import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { X } from 'react-bootstrap-icons';
import '../../App.css';

import { TagForm, Alert } from '../../wikiBuilder';

import ExternalLorePreview from './ExternalLorePreview';

const EditExternalForm = ({
  external,
  updateExternal,
  onSaveSuccess,
}) => {
  const [alert, setAlert] = useState(null);

  const [showValidation, setShowValidation] = useState('');

  const [name, setName] = useState(external.name);
  const [tags, setTags] = useState(external.tags);
  const [sources, setSources] = useState(external.sources);
  const [newSourceName, setNewSourceName] = useState('');
  const [newSourceUrl, setNewSourceUrl] = useState('');

  const nameInvalid = () => name === '';
  const tagsInvalid = () => tags.length === 0;
  const sourcesInvalid = () => sources.length === 0;

  const handleAddSource = e => {
    e.preventDefault(); e.stopPropagation();
    let useUrl = newSourceUrl.trim();
    if (newSourceName === '' || newSourceUrl === '') return;
    if (useUrl.slice(0,8) !== 'https://') useUrl = 'https://' + useUrl;
    setSources([ ...sources, {
      name: newSourceName.trim(),
      url: useUrl,
    }]);
    setNewSourceName(''); setNewSourceUrl('');
  }

  const removeSource = i => {
    const newSources = [ ...sources ];
    newSources.splice(i, 1);
    setSources(newSources);
  }

  const handleSave = async () => {
    if (nameInvalid() || tagsInvalid() || sourcesInvalid()) {
      setShowValidation(true);
      return;
    }
    const succeeded = await updateExternal(external._id, {
      name,
      sources,
      tags,
    });
    if (succeeded) {
      onSaveSuccess?.();
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  }

  return (
    <Card
      className={`
        w-100 my-3
      `}
      bg={'light'}
      text={'dark'}
    >
      <Card.Header>Edit Compendium Entry</Card.Header>

      <Card.Body>
        <Alert alert={alert} setAlert={setAlert} />
        <Form.Group className='mb-3' controlId='externalTitle'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='What is the Dark Sea'
            value={name}
            onChange={e => setName(e.target.value)}
            className={showValidation && nameInvalid() && 'border-danger'}
          />
          {showValidation && nameInvalid() && <div className='text-danger'>Name can't be blank</div>}
        </Form.Group>

        <Form.Label>Sources</Form.Label>
          <Card body bg='light' className='mb-3'>
            <div className={`d-flex flex-wrap`}>
              {sources.map((source, i) => (
                <Badge
                  variant="info"
                  pill
                  className='m-1 d-flex align-items-center justify-content-between'
                  key={`source_${i}`}
                >
                  <p className={`m-0 d-inline-block px-1 text-left`} style={{ whiteSpace: 'pre-wrap' }}>{source.name}</p>
                  <Button
                    size='sm'
                    className='bg-transparent border-0 p-0'
                    onClick={() => removeSource(i)}>
                    <X />
                  </Button>
                </Badge>
              ))}
            </div>
            <Form onSubmit={handleAddSource}>
              <Form.Label>Source Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='Source Title'
                value={newSourceName}
                onChange={e => setNewSourceName(e.target.value)}
                className={showValidation && sourcesInvalid() && 'border-danger'}

              />
              <Form.Label>Source URL</Form.Label>
              <Form.Control
                type='text'
                placeholder='https://youtu.be/E32LI3X71jI'
                value={newSourceUrl}
                onChange={e => setNewSourceUrl(e.target.value)}
                className={showValidation && sourcesInvalid() && 'border-danger'}
              />
              {showValidation && sourcesInvalid() && <div className='text-danger'>Must have at least one source</div>}

              <Button variant='primary' type='submit' className='mt-3' disabled={!(newSourceName && newSourceUrl)}>
                Add
              </Button>
            </Form>
          </Card>

        <Form.Group className='mb-3' controlId='tags'>
          <Form.Label>Tags</Form.Label>
          <TagForm
            initialTags={tags}
            onChange={tags => setTags(tags)}
            badgeClasses='px-1'
            inputClasses={showValidation && tagsInvalid() && 'border-danger'}
          />
          {showValidation && tagsInvalid() && <div className='text-danger'>We need tags to find this later!</div>}
        </Form.Group>

        <hr />

        <Form.Label>Preview</Form.Label>
        <ExternalLorePreview
          external={{
            name,
            sources,
            tags,
          }}
        />

        <Button
          variant='success'
          type='submit'
          onClick={handleSave}
        >
          Save
        </Button>
      </Card.Body>
    </Card>
  );
}

export default EditExternalForm;
