import { useState, useEffect } from 'react';

// import useCreateEntity from './useCreateEntity';
// import useDeleteEntity from './useDeleteEntity';
import useGetEntity from './useGetEntity';
import useUpdateEntity from './useUpdateEntity';


const useEntity = (type) => {
  const [entity, setEntity] = useState(null);

  const {
    getEntity,
    entity: initialEntity,
    loading: fetching,
  } = useGetEntity(type);

  // const {
  //   createEntity,
  //   entity: newEntity,
  //   loading: creating,
  // } = useCreateEntity(type);

  const {
    updateEntity,
    entity: updatedEntity,
    loading: updating,
  } = useUpdateEntity(type);

  // const {
  //   deleteEntity,
  //   entity: deletedEntity,
  //   loading: deleting,
  // } = useDeleteEntity(type);

  useEffect(() => {
    if (initialEntity)
      setEntity(initialEntity);
  }, [initialEntity]);

  // useEffect(() => {
  //   if (newEntity)
  //     setEntity(newEntity);
  // }, [newEntity]);

  useEffect(() => {
    if (updatedEntity)
      setEntity({ ...entity, ...updatedEntity });
  }, [updatedEntity]);

  // useEffect(() => {
  //   if (deletedEntity)
  //     setEntity(null);
  // }, [deletedEntity]);

  return {
    entity,

    getEntity,
    fetching,

    // createEntity,
    // creating,

    updateEntity,
    updating,

    // deleteEntity,
    // deleting,
  }
};

export default useEntity;