import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const usePublishTopic = () => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const publishTopic = useCallback(async (id) => {
    try {
      const resource = await put(`/topics/${id}/publish`, {
        updatedBy: 'genshin-lore',
      });
      if (response.ok) {
        setTopic(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error publishing topic:', e, id);
    }
  }, [response, put]);

  const unpublishTopic = useCallback(async (id) => {
    try {
      const resource = await put(`/topics/${id}/unpublish`, {
        updatedBy: 'genshin-lore',
      });
      if (response.ok) {
        setTopic(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error publishing topic:', e, id);
    }
  }, [response, put]);

  return {
    topic,
    publishTopic,
    unpublishTopic,
    response,
    loading,
    error,
  };
}

export default usePublishTopic;
