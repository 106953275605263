import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useGetTopic = () => {
  const {
    get,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);
  
  const getTopic = useCallback(async (id) => {
    try {
      const resource = await get(`/topics/${id}`);
      if (response.ok) {
        setTopic({ categories: [], ...resource });
      } else {
        setError(resource);
      }
    } catch (e) {
      handleError('Error getting topic:', e, id);
    }
  }, [response, get]);

  return {
    topic,
    getTopic,
    response,
    loading,
    error,
  };
}

export default useGetTopic;
