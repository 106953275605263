import React, { useState, useEffect, useCallback } from 'react';
import { SlateEditor } from '@react-force/slate-editor';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Spinner from 'react-bootstrap/Spinner';

const defaultValue = [
  {
    type: 'paragraph',
    children: [{text: '' }],
  },
];

const TextEditor = ({
  initialValue,
  onSave,
  onChange,
  onCancel,
  saving,
}) => {
  const [value, setValue] = useState(initialValue || defaultValue);
  const [firstRender, setFirstRender] = useState(true);

  const keydownListener = useCallback(async keydownEvent => {    
    const { code, metaKey, altKey, repeat } = keydownEvent;
    if (repeat) return;
    if (metaKey && code === 'KeyS') {
      keydownEvent.preventDefault();
      await onSave(value);
      if (altKey) {
        onCancel();
      }
    }
  }, [value]);

  useEffect(() => {  
    window.addEventListener('keydown', keydownListener, true);  
    return () => window.removeEventListener('keydown', keydownListener, true);
  }, [keydownListener]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    onChange?.(value);
  }, [value]);

  return (
    <>
      <Card body>
        <SlateEditor value={value} onChange={setValue} />
      </Card>
      <ButtonToolbar className='d-flex justify-content-end'>
        <Button
          variant='link'
          className='mt-3 font-weight-bold'
        >
          {saving && <Spinner animation='border' className='text-dark' size='sm' />}
        </Button>
        <Button
          variant='primary'
          className='mt-3 font-weight-bold'
          onClick={async () => {
            await onSave(value);
            onCancel();
          }}
        >
          <span>Save and Close</span>
        </Button>
      </ButtonToolbar>
    </>
  );
};

export default TextEditor;