
import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';

const CharacterListItem = ({ entity: character, ...rest }) => {
  return (
    <EntityCard
      Title={() => <ListItemTitle
        imageUrl={character.portraitImageUrl}
        title={character.name}
      />}
      startClosed
      {...rest}
    >
      {character.attributesDescription
        ? <p className={'attributes_description'}>{character.attributesDescription}</p>
        : <p className='text-muted'>We don't know much about this person</p>
      }
    </EntityCard>
  );
};

export default CharacterListItem;