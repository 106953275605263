import Helmet from 'react-helmet';

const HtmlHead = ({ title, topic }) => {
  const _title = title || topic.articleTitle || 'Genshin Lore';
  const description = 'Easily research Genshin lore';
  const image = 'https://lh3.googleusercontent.com/zQXZe5bbueGbmYvM0uqon_8lzyUYqYHE09GsyX6U9Fhu7Y6iVbuoDNk7fXFWLBQPSW94PFPoVPCi1ITr8ec2H02YzSxARSlJXFOvVLUYtvQ_kHop8Ts5JzJMJLFxURWd9fw-iMgQqA=w2400';

  return (
    <Helmet>
      <title>{_title}</title>
      <meta name='description' content={description} />

      {/*<!-- Schema.org markup for Google+ -->*/}
      <meta itemprop='name' content={_title} />
      <meta itemprop='description' content={description} />
      <meta itemprop='image' content={image} />

      {/*<!-- Twitter Card data -- />*/}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@wiggenwell' />
      <meta name='twitter:title' content={_title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:creator' content='@wiggenwell' />
      {/*<!-- Twitter summary card with large image must be at least 280x150px -- />*/}
      <meta name='twitter:image:src' content={image} />

      {/*<!-- Open Graph data -->*/}
      <meta property='og:title' content={_title} />
      <meta property='og:type' content='article' />
      <meta property='og:url' content='http://www.genshinlore.com/' />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={description} />
      <meta property='og:site_name' content='Genshin Lore' />
    </Helmet>
  );
};

export default HtmlHead;