import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useDeleteExternalLore = () => {
  const {
    del,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [external, setExternal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const deleteExternal = useCallback(async (id) => {
    try {
      const resource = await del(`/articles/${id}`);
      if (response.ok && !error) {
        setExternal(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error deleting external:', e, id);
    }
  }, [response, del]);

  return {
    external,
    deleteExternal,
    response,
    loading,
    error,
  };
}

export default useDeleteExternalLore;
