import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const PublishTopicButton = ({
  topic,
  topicApi,
  onPublishSuccess,
  onPublishFail,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    publishTopic,
    unpublishTopic,
    publishing,
  } = topicApi;

  const handlePublish = async () => {
    const func = topic.datePublished ? unpublishTopic : publishTopic;
    const succeeded = await func(topic._id);
    if (succeeded) {
      onPublishSuccess?.(topic.datePublished ? 'unpublished' : 'published');
    } else {
      onPublishFail?.();
    }
    setConfirmationModalOpen(false);
  };

  return (
    <div className='m-3 d-flex justify-content-end'>
      <Button
        variant={topic.datePublished ? 'warning' : 'success'}
        className='font-weight-bold'
        onClick={() => setConfirmationModalOpen(true)}
      >
        {topic.datePublished ? 'Unpublish' : 'Publish'}
      </Button>
      <Modal
        show={confirmationModalOpen}
        onHide={() => setConfirmationModalOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant='light' onClick={() => setConfirmationModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant={topic.datePublished ? 'warning' : 'success'}
            onClick={handlePublish}
          >
            {publishing
              ? <Spinner animation='border' size='sm' />
              : <span>{topic.datePublished ? 'Unpublish' : 'Publish'}</span>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PublishTopicButton;
