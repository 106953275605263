import Alert from 'react-bootstrap/Alert';


const MAlert = ({ alert, setAlert }) => {
    if (!alert) return null;

    return (
      <>
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.message}
        </Alert>
      </>
    );
};

export default MAlert;