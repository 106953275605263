import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import FormControl from 'react-bootstrap/FormControl';

const DeleteTopicButton = ({
  topic,
  deleteTopic,
  deleting,
  onDeleteSuccess,
  onDeleteFail,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState('');

  const handleDelete = async () => {
    const succeeded = await deleteTopic(topic._id);
    if (succeeded) {
      onDeleteSuccess?.();
    } else {
      onDeleteFail?.();
    }
  };

  return (
    <div className='m-3 d-flex justify-content-end'>
      <Button
        variant='outline-danger'
        className='font-weight-bold'
        onClick={() => setConfirmationModalOpen(true)}
      >
        Delete
      </Button>
      <Modal
        show={confirmationModalOpen}
        onHide={() => setConfirmationModalOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Type the name of this topic to confirm deletion.</p>
          <FormControl
            value={confirmationInput}
            placeholder={topic.articleTitle}
            onChange={e => setConfirmationInput(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light' onClick={() => setConfirmationModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={handleDelete}
            disabled={topic.articleTitle !== confirmationInput}
          >
            {deleting
              ? <Spinner animation='border' size='sm' />
              : <span>Delete</span>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DeleteTopicButton;
