import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useUpdateTopic = () => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const updateTopic = useCallback(async (id, payload) => {
    delete payload.datePublished; // disallow publishing in standard update
    delete payload.name; // disallow attempting to edit name
    
    try {
      const resource = await put(`/topics/${id}`, {
        data: {
          ...payload,
          updatedBy: 'genshin-lore',
        },
      });
      if (response.ok && !error) {
        setTopic(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error updating topic:', e, payload);
    }
  }, [response, put]);

  return {
    topic,
    updateTopic,
    response,
    loading,
    error,
  };
}

export default useUpdateTopic;
