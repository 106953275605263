import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';

import { Plus } from 'react-bootstrap-icons';

import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import useTopicList from '../hooks/useTopics/useTopicList';
import useUser from '../contexts/useUser';
import { useGenshinAuth } from '../contexts/GenshinAuthContext';

import {
  HtmlHead,
  toUri,
  NewTopicForm,
  TopicSearchBar,
  Topic,
  TopicList,
  Sidebar,
  EntityAvatar,
} from '../wikiBuilder';

import WiggyAvatar from '../images/wiggenThink_500.png';
import TopicDiagram from '../images/howTopicsWork.png';

const NoTopicsBox = ({ onCreateTopic, creating }) => {
  const [creatingTopic, setCreatingTopic] = useState(false);

  return (
    <Card body className='shadow m-3'>
      <Card.Title className='d-flex align-items-center'>
        <EntityAvatar size={40} src={WiggyAvatar} className='mr-3' />
        <p className='m-0 font-weight-bold'>Create a topic for anything you want to research.</p>
      </Card.Title>
      <p>We'll automagically link any <strong>primary source</strong> that mentions your topic, so you don't need to scour the library!</p>
      <div className='p-2 d-flex justify-content-center mb-3'>
        {creatingTopic
          ? (
            <div className='text-center w-100'>
              <p><strong>Ideas:</strong> Arundolyn, Cataclysm, Vision, Aerosiderite</p>
              <NewTopicForm onCreateTopic={onCreateTopic} creating={creating} className='justify-content-center' /> 
            </div>
          ) : (
            <Button variant='success' onClick={() => setCreatingTopic(true)}>
              <p className='px-3 h4'>Create a topic</p>
              <Plus size={40} />
              <p className='px-3 mb-2'>Become a loreist</p>
            </Button>
          )
        }
      </div>
      <h2 className='font-weight-bold'>How Topics Work</h2>
      <Image src={TopicDiagram} fluid rounded />
    </Card>
  );
}

const MyTopics = ({ topicApi }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [alert, setAlert] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const setUserTopics = useUser(state => state.setTopics);

  const {
    topics,
    fetching,

    createTopic,
    creating,
  } = topicApi;

  const handleTopicSelect = (selected) => {
    if (selected.length === 0) {
      history.push(match.path);
      return;
    }
    history.push(`${match.path}/${toUri(selected[0].articleTitle)}`);
  };

  const handleCreate = async (name) => {
    const succeeded = await createTopic(name);
    if (!succeeded) {
      setAlert({
        variant: 'danger',
        message: 'Oh no, something went wrong! Try again?'
      });
    }
  };

  useEffect(() => {
    if (!topics?.length) return;
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    setUserTopics(topics);
    history.push(`${match.path}/${toUri(topics[topics.length-1].name)}`);

  }, [topics?.length]);

  return (
    <>
      {alert && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <h1 className='mb-3'>My Research Topics</h1>
      {topics?.length !== 0 && 
        <TopicSearchBar
          topics={topics}
          onSelect={handleTopicSelect}
          size='lg'
          highlightOnlyResult
        />
      }

      {!topics && (
        <div className='d-flex justify-content-center align-items-center'>
          <Spinner animation='border' />
        </div>
      )}

      {(!fetching && topics && !topics.length) &&
        <NoTopicsBox onCreateTopic={handleCreate} creating={creating} />
      }
      
      {!fetching && topics && topics.length !== 0 && (
        <>
          <NewTopicForm onCreateTopic={handleCreate} creating={creating} />
          <TopicList topics={topics} />
        </>
      )}
    </>
  );
};

const LoginPrompt = () => {
  const { links } = useGenshinAuth();

  return (
    <div className='m-3 w-100 d-flex flex-column justify-content-center align-items-center'>
      <a href={links.login}>
        <div className='btn btn-electro btn-lg'>
          Log in to create custom research results
        </div>
      </a>
      <p>(We use Discord, so no new account!)</p>
    </div>
  )
}

const TopicRouter = () => {
  const match = useRouteMatch();
  const topicApi = useTopicList();
  const loggedIn = useUser(state => state.loggedIn);

  useEffect(() => {
    if (loggedIn) topicApi.getTopics();
  }, [loggedIn]);

  if (loggedIn) return (
    <Switch>

      <Route path={`${match.path}/:topicTitle/`}>
        <Topic
          topicApi={topicApi}
          backUrl={match.path}
        />
      </Route>

      <Route path={match.path}>
        <MyTopics topicApi={topicApi} />
      </Route>

    </Switch>
  );

  return <LoginPrompt />
};

const Profile = () => {
  return (
    <>
      <HtmlHead title={`My Topics`} />

      <Col lg={9}>
        <TopicRouter />
      </Col>

      <Col lg={3} sm={6}>
        <Sidebar />
      </Col>
    </>
  );
}

export default Profile;
