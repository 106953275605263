import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useCreateAssociation = () => {
  const {
    post,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [error, setError] = useState(null);
  const [association, setAssociation] = useState(null);

  useEffect(_ => setError(fetchError), [fetchError]);

  const createAssociation = useCallback(async (payload) => {
    try {
      const resource = await post('associations/', { data: { ...payload } });
      if (response.ok) {
        setAssociation(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error creating association:', e, payload);
    }
  }, [response, post]);

  return {
    createAssociation,
    association,
    response,
    loading,
    error,
  };
};

export default useCreateAssociation;
