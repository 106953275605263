import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { QuestionCircleFill } from 'react-bootstrap-icons';

const HelpPopover = ({ Message }) => {

  const popover = (
    <Popover>
      <Popover.Content>
        <Message />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger='click' placement='top' overlay={popover}>
      <QuestionCircleFill className='text-info' role='button' />
    </OverlayTrigger>
  );
}

export default HelpPopover;
