import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { CaretDownFill } from 'react-bootstrap-icons';

import { associationPresenter } from '../../components/entityConfig';

import {
  EntityTable,
  AssociationTableRow,
  LoreMissing,
  EntityCardTitle,
} from '../../wikiBuilder';

const VoicelineAssociations = ({ showOnly, lines, assocApi, setAlert, theme }) => {
  const [headerMouseOver, setHeaderMouseOver] = useState(false);

  const element = theme || 'grey';
  const bg = `bg-${element}`;
  const activeBg = `bg-${element}-hover`;

  return (
    <Accordion
      defaultActiveKey='0'
      className='my-2'
    >
        <Accordion.Toggle
          as={Card.Header}
          className={`
            ${headerMouseOver ? activeBg : bg}
            association-hook-card py-2
            d-flex align-items-center justify-content-between
          `}
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onMouseEnter={() => setHeaderMouseOver(true)}
          onMouseLeave={() => setHeaderMouseOver(false)}
        >
          <EntityCardTitle title='Voicelines' />
          <div className='float-right'><CaretDownFill /></div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0'>
          <EntityTable
            titleColName={'Voiceline'}
          >
            {lines?.map((assoc, i) => {
              const {
                title,
                content,
                loreMissing,
                notes,
              } = associationPresenter(assoc);
              return (
                <AssociationTableRow
                  showOnly={showOnly}
                  title={title}
                  htmlContent={content}
                  notes={notes}
                  association={assoc}
                  assocApi={assocApi}
                  setAlert={setAlert}
                  key={`vc_associations_${i}`}
                >
                  {loreMissing && <LoreMissing />}
                </AssociationTableRow>
              );
            })}
          </EntityTable>
        </Accordion.Collapse>
    </Accordion>
  );
};

export default VoicelineAssociations;