import { toUri } from '../wikiBuilder';


export const Story = ({ from, to, markdownNotes }) => ({
  title: `[${from._id.character.name}] ${from._id.name}`,
  imageUrl: from._id.character.portraitImageUrl,
  content: from._id.html,
  loreMissing: !from._id.html,
  notes: markdownNotes,
  showPageUri: `/researcher/Character/${toUri(from._id.character.name)}`
});

export const Voiceline = ({ from, to, markdownNotes }) => ({
  title: `[${from._id.character.name}] ${from._id.name}`,
  imageUrl: from._id.character.portraitImageUrl,
  content: from._id.content,
  loreMissing: !from._id.content,
  notes: markdownNotes,
  showPageUri: `/researcher/Character/${toUri(from._id.character.name)}`
});

export const Monster = ({ from, to, markdownNotes }) => ({
  title: from._id.name,
  imageUrl: from._id.imageUrl,
  content: from._id.lore,
  loreMissing: !from._id.lore,
  notes: markdownNotes,
  showPageUri: `/researcher/${from.type}/${toUri(from._id.name)}`
});

export const Artifact = ({ from, to, markdownNotes }) => ({
  title: `[${from._id.set.name}] ${from._id.name}`,
  imageUrl: from._id.imageUrl,
  intro: from._id.description,
  content: from._id.lore,
  loreMissing: !from._id.lore,
  notes: markdownNotes,
  showPageUri: `/researcher/ArtifactSet/${toUri(from._id.set.name)}`
});

export const BookVolume = ({ from, to, markdownNotes }) => ({
  title: `[${from._id.book.name}] ${from._id.name}`,
  imageUrl: from._id.book.imageUrl,
  intro: from._id.description,
  content: from._id.content,
  loreMissing: !from._id.content,
  notes: markdownNotes,
  showPageUri: `/researcher/Book/${toUri(from._id.book.name)}`
});

export const Item = ({ from, to, markdownNotes }) => ({
  title: from._id.name,
  imageUrl: from._id.imageUrl,
  intro: from._id.lore ? from._id.description : null,
  content: from._id.lore || from._id.description,
  loreMissing: !from._id.lore && !from._id.description,
  notes: markdownNotes,
  showPageUri: `/researcher/${from.type}/${toUri(from._id.name)}`
});

export const LoadingScreen = ({ from, to, markdownNotes }) => ({
  title: from._id.name,
  imageUrl: from._id.imageUrl,
  content: from._id.lore,
  loreMissing: !from._id.lore,
  notes: markdownNotes,
  showPageUri: `/researcher/${from.type}/${toUri(from._id.name)}`
});

export const Weapon = ({ from, to, markdownNotes }) => ({
  title: from._id.name,
  imageUrl: from._id.imageUrl,
  content: from._id.lore,
  loreMissing: !from._id.lore,
  notes: markdownNotes,
  showPageUri: `/researcher/${from.type}/${toUri(from._id.name)}`
});
