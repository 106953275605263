import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useUpdateAssociation = () => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [association, setAssociation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const updateAssociation = useCallback(async (id, payload) => {
    try {
      const resource = await put(`/associations/${id}`, {
        data: {
          ...payload,
          updatedBy: 'genshin-lore',
        }
      });
      if (response.ok && !error) {
        setAssociation(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error updating association:', e, payload);
    }
  }, [response, put]);

  return {
    association,
    updateAssociation,
    response,
    loading,
    error,
  };
}

export default useUpdateAssociation;
