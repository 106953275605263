import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useDeleteTopic = () => {
  const {
    del,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const deleteTopic = useCallback(async (id) => {
    try {
      const resource = await del(`/topics/${id}`);
      if (response.ok && !error) {
        setTopic(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error deleting topic:', e, id);
    }
  }, [response, del]);

  return {
    topic,
    deleteTopic,
    response,
    loading,
    error,
  };
}

export default useDeleteTopic;
