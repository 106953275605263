import { compareOrder } from '../utils';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Volume from './Volume';

import {
  ImageCard,
} from '../../wikiBuilder';

export const BookIntro = ({
  resource: book,
  className,
}) => {
  const {
    name,
    imageUrl,
  } = book;

  return (
    <Row className='mb-5'>
      <Col md='6'>
        <Card className={`p-0 ${className}`}>
          <Card.Header className={'h2'}>
            <span className={'mr-2 font-weight-bold'}>{name}</span>
          </Card.Header>
        </Card>
      </Col>
      <Col xl='4' lg='5' md='6'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>
  );
}

const Book = ({ resource: book }) => {
  const { volumes, imageUrl } = book;

  return (
    <div className='w-100'>
      {volumes?.sort(compareOrder)?.map(
        (volume, i) => <Volume
          volume={{ ...volume, imageUrl }}
          belongsTo={book}
          key={`volume_${i}`}
        />
      )}
    </div>
  );
}

export default Book;
