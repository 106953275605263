import { useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Association from './Association';
import { Link, useLocation } from 'react-router-dom';

import { CaretDownFill } from 'react-bootstrap-icons';

import useAssociationList from '../../hooks/useAssociations/useAssociationList';

import {
  ListItemTitle,
  RichTextBox,
  toUri,
} from '../../wikiBuilder';

const NotesCard = ({ topic, theme }) => {
  const [headerMouseOver, setHeaderMouseOver] = useState(false);

  const element = theme || 'white';
  const bg = `bg-${element}`;
  const activeBg = `bg-${element}-hover`;

  return (
    <Accordion
      defaultActiveKey={undefined}
      className='my-2 w-100'
    >
    <Card
        className={'w-100 association-hook-card'}
        bg={'white'}
        text={'dark'}
      >
        <Accordion.Toggle
          as={Card.Header}
          className={`
            ${headerMouseOver ? activeBg : bg}
            association-hook-card py-2
            d-flex align-items-center justify-content-between
          `}
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onMouseEnter={() => setHeaderMouseOver(true)}
          onMouseLeave={() => setHeaderMouseOver(false)}
        >
          <ListItemTitle
            title={'Notes'}
          />
          <div className='float-right'><CaretDownFill /></div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey='0'>
          <Card.Body className='py-0'>
            <RichTextBox
              value={JSON.parse(topic.markdownNotes)}
              emptyPlaceholder='No notes yet'
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const TopicAssociation = ({ association, theme }) => {
  const [headerMouseOver, setHeaderMouseOver] = useState(false);

  const topic = association.from._id;
  const { pathname } = useLocation();
  const page = pathname.split('/')[1];
  const linkTo = `/${page}/${toUri(topic.articleTitle)}`;

  const element = theme || 'grey';
  const bg = `bg-${element}`;
  const activeBg = `bg-${element}-hover`;

  const assocApi = useAssociationList('Topic', 'to');
  const {
    associations,
    getAssociations,
  } = assocApi;

  useEffect(() => {
    getAssociations(topic._id);
  }, [topic._id]);

  const publishedOnly = _ => page === 'articles';

  // Since this is already a topic association and we only want one layer, don't
  // render topic associations inside a topic association
  const notTopic = a => a.from.type !== 'Topic';

  if ((publishedOnly() && !topic.datePublished) || !associations) return null;

  return (
    <Accordion
      defaultActiveKey={undefined}
      className='my-2 w-100'
    >
    <Card
        className={'w-100 association-hook-card border-0'}
        bg={'white'}
        text={'dark'}
      >
        <Accordion.Toggle
          as={Card.Header}
          className={`
            ${headerMouseOver ? activeBg : bg}
            association-hook-card py-2
            border-0 d-flex align-items-center justify-content-between
          `}
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onMouseEnter={() => setHeaderMouseOver(true)}
          onMouseLeave={() => setHeaderMouseOver(false)}
        >
          {linkTo
            ? (
              <Link to={linkTo} role='button' className='text-dark' onClick={e => e.stopPropagation()}>
                <ListItemTitle title={topic.articleTitle} />
              </Link>
            ) : <ListItemTitle title={topic.articleTitle} />
          }
          
          <div className='float-right'><CaretDownFill /></div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            <NotesCard topic={topic} />
            {associations.filter(notTopic).map((assoc, i) => <Association showOnly association={assoc} key={`${topic.name}_assoc_${i}`} /> )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

const AssociatedTopics = ({ associations, theme }) => {
  return (
    <>
      {associations.map((topicAssoc, i) => <TopicAssociation association={topicAssoc} theme={theme} key={`topic_assoc_${i}`}/>) }
    </>
  );

}

export default AssociatedTopics;