import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  ImageCard,
  Aliases,
} from '../../wikiBuilder';

export const RegionIntro = ({
  resource: region,
  onSave,
  editable,
  className,
}) => {
  const {
    imageUrl,
    name,
    aliases,
  } = region;

  return (
    <Row className='mb-3'>
      <Col md='9'>
        <Card className={`p-0 ${className}`}>
          <Card.Header>
            <span className={'h2 mr-2 font-weight-bold'}>{name}</span>
            <Aliases
              aliases={aliases}
              editable={editable}
              onSave={onSave}
            />
          </Card.Header>
        </Card>
      </Col>

      <Col md='3'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>       
  );
};

const Region = ({ resource: region }) => {
  return null;
}

export default Region;
