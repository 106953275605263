import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useCreateExternalLore = () => {
  const {
    post,
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [external, setExternal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const createExternal = useCallback(async (payload) => {
    try {
      const resource = await post('/articles', {
        data : {
          ...payload,
        },
      });
      if (response.ok) {
        setExternal(resource);
        await put(`/articles/${resource._id}/publish`);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error creating external:', e, payload.name);
    }
  }, [response, post]);

  return {
    external,
    createExternal,
    response,
    loading,
    error,
  };
}

export default useCreateExternalLore;
