import Card from 'react-bootstrap/Card';

import ExternalLorePreview from './ExternalLorePreview';

const ExternalList = ({ externals, externalApi, showPlaceholder, suppressNote }) => {
  if (externals?.length) return (
    <>
      {!suppressNote && 
        <Card body className='my-3 shadow-sm '>
          Please note that these posts compile a ton of lore and contain speculation 
          in nature. Many theories may contain inaccuracies. Older posts may be 
          obsolete as time passes. <span className='text-electro'>Read with an analytical mind!</span>
        </Card>
      }
      {externals?.map(e => <ExternalLorePreview external={e} externalApi={externalApi} editable />)}
    </>
  );

  if (showPlaceholder) return (
    <p className='text-muted m-3'>There aren't any matching entries in the external library</p>
  );

  return null;
};

export default ExternalList;