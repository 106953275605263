import { useEffect } from 'react';
import Table from 'react-bootstrap/Table';

import { EntityAvatar, AssociatedToBlock } from '../common';

import { Link } from 'react-router-dom';
import AddAssociationsButton from '../association/creation/AddAssociationsButton';
import useAssociationList from '../../hooks/useAssociations/useAssociationList';
import useUser from '../../contexts/useUser';

export const EntityTableRowTitle = ({ imageUrl, title }) => (
  <div className='d-flex flex-column flex-sm-row align-items-start justify-content-start'>
    {imageUrl && <span className='mr-3'><EntityAvatar size='25px' src={imageUrl} /></span>}
    <div className='my-0'>{title}</div>
  </div>
);

export const EntityTableRow = ({
  associable,
  from,
  title,
  imageUrl,
  className,
  children,
  htmlContent,
  titleColSpan = 3,
  linkTo,
}) => {
  const loggedIn = useUser(state => state.loggedIn);
  const userTopics = useUser(state => state.topics);

  const assocApi = useAssociationList(from?.type, 'from');
  const {
    associations,
    getAssociations,
  } = assocApi;

  useEffect(() => {
    if (from?.data?._id) {
      getAssociations(from.data._id, userTopics);
    }
  }, [from?.data?._id]);

  return (
    <tr className='row m-0 p-0'>
      {linkTo
          ? (
            <td className={`col-${titleColSpan} font-weight-bold`}>
              <Link to={linkTo} role='button' className='text-dark'>
                <EntityTableRowTitle title={title} imageUrl={imageUrl} />
              </Link>
            </td>
          ) : (
            <td className={`col-${titleColSpan} font-weight-bold`}>
              <EntityTableRowTitle title={title} imageUrl={imageUrl} />
            </td>
          )
        }
      <td className={`col-${11-titleColSpan}`}>
        {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent}} />}
        {children}
        {from && <AssociatedToBlock associations={associations} entity={from.data._id} />}
      </td>
      <td className='col-1'>
        {(associable && from && loggedIn) && (
          <AddAssociationsButton
            from={from}
            assocApi={assocApi}
          />
        )}
      </td>
    </tr>
  );
}

const EntityTable = ({
  titleColName,
  titleColSpan = 3,
  children,
}) => (
  <Table
    bg={'light'}
    text={'dark'}
  >
    <thead>
      <tr className='row m-0 p-0'>
        <th className={`col-${titleColSpan} border-top-0`}>{titleColName}</th>
        <th className={`col-${11-titleColSpan} border-top-0`}>Lore</th>
        <th className='col-1 border-top-0'></th>
      </tr>
    </thead>
    <tbody>
      {children}
    </tbody>
  </Table>
);

export default EntityTable;
