import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import '../../App.css';
import { CaretDownFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import AssociationNotes from './AssociationNotes';
import EditAssociationButton from './EditAssociationButton';
import useUser from '../../contexts/useUser';

const AssociationCard = ({
  showOnly,
  Title,
  htmlIntro,
  bodyClasses,
  bordered,
  htmlContent,
  notes,
  association,
  assocApi = {},
  setAlert,
  linkTo,
  children
}) => {
  const [headerMouseOver, setHeaderMouseOver] = useState(false);
  const [editing, setEditing] = useState(false);

  const canEditAssociations = useUser(state => state.canEditAssociations);

  const {
    updateAssociation,
    updating,
  } = assocApi;

  const handleSave = async (payload) => {
    const succeeded = await updateAssociation(association._id, payload);
    if (succeeded) {
      setAlert(null);
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  };

  return (
    <Accordion
      defaultActiveKey={undefined}
      className='my-2 w-100'
    >
      <Card
        className={`
          ${!bordered ? 'border-0' : ''} 
          w-100 association-hook-card 
        `}
        bg={'light'}
        text={'dark'}
      >
        <Accordion.Toggle
          as={Card.Header}
          className={`
            ${headerMouseOver ? '' : 'bg-transparent'} 
            association-hook-card py-2
            d-flex align-items-center justify-content-between
          `}
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onMouseEnter={() => setHeaderMouseOver(true)}
          onMouseLeave={() => setHeaderMouseOver(false)}
        >
          {linkTo
            ? (
              <Link to={linkTo} role='button' className='text-dark' onClick={e => e.stopPropagation()}>
                <Title />
              </Link>
            ) : <Title />
          }
          
          <div className='float-right'><CaretDownFill /></div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey='0'>
          <Card.Body className={`${bodyClasses}`}>
            {(!showOnly && canEditAssociations()) && (
              <EditAssociationButton
                association={association}
                setEditable={setEditing}
                editable={editing}
                assocApi={assocApi}
                onDeleteFail={() => setAlert({
                  variant: 'danger',
                  message: 'Something went wrong while deleting. Try again?'
                })}
              />
            )}
            <AssociationNotes
              notes={notes}
              editable={editing}
              onSave={(value) => handleSave({
                markdownNotes: JSON.stringify(value),
              })}
              onCancel={() => setEditing(false)}
              saving={updating}
            />
            {htmlIntro && (
              <i>
                <div className='mb-3' dangerouslySetInnerHTML={{ __html: htmlIntro}} />
              </i>
            )}
            {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent}} />}
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default AssociationCard;
