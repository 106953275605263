import { useState, useEffect } from 'react';

import useCreateTopic from './useCreateTopic';
import useDeleteTopic from './useDeleteTopic';
import useGetManyTopics from './useGetManyTopics';
import useUpdateTopic from './useUpdateTopic';
import usePublishTopic from './usePublishTopic';

const useTopicList = () => {
  const [topics, setTopics] = useState(null);

  const {
    getTopics,
    topics: initialTopics,
    loading: fetching,
  } = useGetManyTopics();

  const {
    createTopic,
    topic: newTopic,
    loading: creating,
  } = useCreateTopic();

  const {
    updateTopic,
    topic: updatedTopic,
    loading: updating,
  } = useUpdateTopic();

  const {
    deleteTopic,
    topic: deletedTopic,
    loading: deleting,
  } = useDeleteTopic();

  const {
    publishTopic,
    unpublishTopic,
    topic: publishedTopic,
    loading: publishing,
  } = usePublishTopic();

  useEffect(() => {
    if (initialTopics)
      setTopics(initialTopics);
  }, [initialTopics]);

  useEffect(() => {
    if (newTopic)
      setTopics([ ...topics, newTopic]);
  }, [newTopic]);

  useEffect(() => {
    if (updatedTopic) {
      const newArr = [...topics];
      const changedIdx = topics.findIndex(t => t._id === updatedTopic._id);
      newArr.splice(changedIdx, 1, updatedTopic);
      setTopics(newArr);
    }
  }, [updatedTopic]);

  useEffect(() => {
    if (deletedTopic)
      setTopics(topics.filter(t => t.name !== deletedTopic.name));
  }, [deletedTopic]);

  useEffect(() => {
    if (publishedTopic) {
      const newArr = [...topics];
      const changedIdx = topics.findIndex(t => t._id === publishedTopic._id);
      newArr.splice(changedIdx, 1, publishedTopic);
      setTopics(newArr);
    }
  }, [publishedTopic]);

  return {
    topics,

    getTopics,
    fetching,

    createTopic,
    creating,

    updateTopic,
    updating,

    deleteTopic,
    deleting,

    publishTopic,
    unpublishTopic,
    publishing,
  }
};

export default useTopicList;