import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { getEntityRoute } from '../../components/entityConfig';

import { handleError } from '../utils';

const useGetEntity = (type) => {
  const {
    get,
    response,
    request,
    loading,
    error: fetchError,
  } = useFetch();

  const [entity, setEntity] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const getEntity = useCallback(async (id) => {
    let url = `${getEntityRoute(type)}/${id}`;

    try {
      const resource = await get(url);
      if (response.ok) {
        setEntity(resource);
      }
    } catch (e) {
      handleError('Error getting resource:', e, { type, id });
    }
  }, [response, get, type]);

  return {
    getEntity,
    entity,
    request,
    response,
    loading,
    error,
  };
};

export default useGetEntity;
