import { useState, useEffect } from 'react';

import useCreateExternalLore from './useCreateExternalLore';
import useDeleteExternalLore from './useDeleteExternalLore';
import useGetManyExternalLores from './useGetManyExternalLores';
import useUpdateExternalLore from './useUpdateExternalLore';

const useExternalLoreList = () => {
  const [externals, setExternals] = useState(null);

  const {
    getExternals,
    externals: initialExternals,
    loading: fetching,
  } = useGetManyExternalLores();

  const {
    createExternal,
    external: newExternal,
    loading: creating,
  } = useCreateExternalLore();

  const {
    updateExternal,
    external: updatedExternal,
    loading: updating,
  } = useUpdateExternalLore();

  const {
    deleteExternal,
    external: deletedExternal,
    loading: deleting,
  } = useDeleteExternalLore();

  useEffect(() => {
    if (initialExternals)
      setExternals(initialExternals);
  }, [initialExternals]);

  useEffect(() => {
    if (newExternal)
      setExternals([ ...externals, newExternal]);
  }, [newExternal]);

  useEffect(() => {
    if (updatedExternal) {
      const newArr = [...externals];
      const changedIdx = externals.findIndex(t => t._id === updatedExternal._id);
      newArr.splice(changedIdx, 1, updatedExternal);
      setExternals(newArr);
    }
  }, [updatedExternal]);

  useEffect(() => {
    if (deletedExternal)
      setExternals(externals.filter(t => t.name !== deletedExternal.name));
  }, [deletedExternal]);

  return {
    externals,

    getExternals,
    fetching,

    createExternal,
    creating,

    updateExternal,
    updating,

    deleteExternal,
    deleting,
  }
};

export default useExternalLoreList;
