import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import { Link } from 'react-router-dom';
import { CursorFill } from 'react-bootstrap-icons';

// import Geo from '../../images/geoShine800.png';
import Jueyun from '../../images/bookIcon.png';
import CorLapis from '../../images/itemIcon.png';
import Petra from '../../images/artifactIcon.png';
// import Liyue from '../../images/regionIcon.png';
import Vortex from '../../images/weaponIcon.png';
import Azhdaha from '../../images/monsterIcon.png';
import Cocogoat from '../../images/loadingScreenIcon.png';
import Zhongli from '../../images/characterIcon.png';

import WiggyAvatar from '../../images/wiggenThink_500.png';
import { EntityAvatar } from '../common';
import { useGenshinAuth } from '../../contexts/GenshinAuthContext';


const Empty = () => {
  const entityTypes = [
    {
      name: 'Item',
      image: CorLapis,
    },
    {
      name: 'Book',
      image: Jueyun,
    },
    {
      name: 'LoadingScreen',
      image: Cocogoat,
    },
    {
      name: 'Monster',
      image: Azhdaha,
    },
    {
      name: 'ArtifactSet',
      image: Petra,
    },
    {
      name: 'Character',
      image: Zhongli,
    },
    {
      name: 'Weapon',
      image: Vortex,
    },
    // {
    //   name: 'Region',
    //   image: Liyue,
    // },
    // {
    //   name: 'Element',
    //   image: Geo,
    // }

  ];

  const { links } = useGenshinAuth();

  return (
    <>
    <Card body className='shadow m-3'>
        <Card.Title className='d-flex align-items-center'>
          <EntityAvatar size={40} src={WiggyAvatar} className='mr-3' />
          <p className='m-0'>Hello, friends! I'm Wiggen, the lead of this project.</p>
        </Card.Title>
        <hr />
        <p>
          We're here to clearly present the lore - given to us in nonlinear fragments 
          from unreliable narrators - and make it accessible to the greater fanbase. Our 
          first stage is making it easy to pull together information about any topic 
          you want, whether they it's hiding in items, weapons, artifacts, or even loading 
          screens!
        </p>
        <h4 className='font-weight-bold'>Research Your Own Topics</h4>
        <p>
          The research tool allows you to add search terms to your custom topics. When you add 
          search terms, we automagically collect sources that mention your topic, instead of you 
          scouring the library for it.
        </p>
        <p><a href={links.login}>Log in with Discord to create topics</a></p>
        <p>
          If there's anything that you know is related, but doesn't mention your 
          search terms outright, you can use the <CursorFill className='text-dark mx-1' size={12} />  
           button to manually associate a source to your topic.
        </p>
    </Card>
    <CardDeck className='m-auto'>
      {entityTypes.map(et => (
        <Link
          to={`/researcher/${et.name}`}
          key={`index_${et.name}`}
        >
          <Card className='text-dark text-center font-weight-bold m-3'>
            <Card.Img
              variant="top"
              className='pt-3'
              style={{
                width: '18rem',
                maxHeight: '12rem',
                objectFit: 'contain',
              }}
              src={et.image}
            />
            <Card.Body className='pt-0'>
              <hr className='mt-0' />
              <Card.Title className='mb-0'>{et.name}</Card.Title>
            </Card.Body>
          </Card>
        </Link>
      ))}
    </CardDeck>
    </>
  );
};

export default Empty;
