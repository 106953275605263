import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Fuse from 'fuse.js'

import NewExternalForm from '../externalLore/NewExternalForm';

import useUser from '../../contexts/useUser';

import AllArticles from './AllArticles';
import FilteredArticles from './FilteredArticles';

const ArticleBrowser = ({ topics, externalApi }) => {
  const fuseOptions = {
    ignoreLocation: true,
    threshold: 0.15,
  };

  const {
    externals,
    createExternal,
  } = externalApi;

  const topicFuse = new Fuse(topics || [], { ...fuseOptions, keys: ['articleTitle', 'aliases'] });
  const externalFuse = new Fuse(externals || [], { ...fuseOptions, keys: ['name', 'tags'] });

  const [searchTerm, setSearchTerm] = useState('');
  const [topicMatches, setTopicMatches] = useState([]);
  const [externalMatches, setExternalMatches] = useState([]);

  const canEditKqm = useUser(state => state.canEditKqm);

  useEffect(() => {
    topicFuse.setCollection(topics);
  }, [topics]);

  useEffect(() => {
    externalFuse.setCollection(externals || []);
  }, [externals]);

  const handleSearchTermChange = (term) => {
    setSearchTerm(term);
    setTopicMatches(topicFuse.search(term).map(r => r.item));
    setExternalMatches(externalFuse.search(term).map(r => r.item));
  };

  return (
    <>
      {canEditKqm() && <NewExternalForm createExternal={createExternal} existingExternals={externals} />}
      <InputGroup hasValidation>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">Search</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          placeholder='the abyss'
          onChange={e => handleSearchTermChange(e.target.value)}
        />
      </InputGroup>
      <hr />

      {searchTerm
        ? <FilteredArticles topics={topicMatches} externals={externalMatches} externalApi={externalApi} />
        : <AllArticles topics={topics} externals={externals} externalApi={externalApi} />
      }
    </>
  );
};

export default ArticleBrowser;