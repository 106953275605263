import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useDeleteAssociation = () => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [error, setError] = useState(null);
  const [association, setAssociation] = useState(null);

  useEffect(_ => setError(fetchError), [fetchError]);

  const deleteAssociation = useCallback(async (id) => {
    try {
      const resource = await put(`associations/${id}/deactivate`, {
        updatedBy: 'genshin-lore',
      });
      if (response.ok) {
        setAssociation(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error deleting association:', e, id);
    }
  }, [response, put]);

  return {
    deleteAssociation,
    association,
    response,
    loading,
    error,
  };
};

export default useDeleteAssociation;
