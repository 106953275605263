import { useEffect, useState } from 'react';
import { fromTargets } from '../../components/entityConfig';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import useUser from '../../contexts/useUser';
import { useLocation } from 'react-router-dom';

import useAssociationList from '../../hooks/useAssociations/useAssociationList';
import AssociatedTopics from './AssociatedTopics';
import Association from './Association';
import VoicelineAssociations from './VoicelineAssociations';

import {
  EntityCard,
  EntityCardTitle,
  toTitleCase,
} from '../../wikiBuilder';

const Associations = ({ showOnly, forEntity, setAlert, refreshCount }) => {
  const [voicelineAssociations, setVoicelineAssociations] = useState([]);
  const [topicAssociations, setTopicAssociations] = useState([]);
  const [other, setOther] = useState([]);
  const setEditingOwn = useUser(state => state.setEditingOwn);

  const assocApi = useAssociationList(forEntity.type, 'to');
  const {
    associations,
    getAssociations,
    fetching,
  } = assocApi;

  const { pathname } = useLocation();
  const page = pathname.split('/')[1];

  const shouldDisplaySubTopics = _ => {
    if (page === 'profile') return true;
    return topicAssociations.some(a => a.from._id.datePublished !== null); 
  }

  useEffect(() => {
    if (!forEntity._id) return;
    if (forEntity.type === 'Topic' && !showOnly) {
      setEditingOwn(true);
    } else {
      setEditingOwn(false);
    }
    getAssociations(forEntity._id);
  }, [forEntity._id, refreshCount]);

  useEffect(() => {
    if (!associations?.length) return;

    setVoicelineAssociations(associations.filter(
      assoc => assoc?.from?.type === 'Voiceline'
    ));

    setTopicAssociations(associations.filter(
      assoc => assoc?.from?.type === 'Topic'
    ));

    setOther(associations.filter(
      assoc => assoc?.from?.type !== 'Voiceline' && assoc?.from?.type !== 'Topic'
    ).sort());
  }, [associations]);

  const typeHasAssociations = assocType => other.filter(a => a.from.type === assocType).length !== 0

  if (fetching || !associations) return <Spinner animation='border' />;
  if (!associations.length) return (
    <div className='h5 text-muted mb-4'>
      No Associations Yet {String.fromCodePoint(0x1F61E)}
    </div>
  );

  return (
    <>
      {topicAssociations.length > 0 && shouldDisplaySubTopics() &&
        <EntityCard
          Title={() => <EntityCardTitle title='Sub Topics' />}
          bodyClasses='px-3'
        >
          <AssociatedTopics
            showOnly={showOnly}
            associations={topicAssociations}
            setAlert={setAlert}
            theme={'hydro'}
          />
        </EntityCard>
      }

      <EntityCard
        Title={() => <EntityCardTitle title='Primary Sources' />}
        bodyClasses='px-3'
      >
        {voicelineAssociations.length > 0 && 
          <VoicelineAssociations
            showOnly={showOnly}
            lines={voicelineAssociations}
            assocApi={assocApi}
            setAlert={setAlert}
            theme={'hydro'}
          />
        }
        {fromTargets.filter(t => t !== 'Voiceline').map((assocType, i) => {
          if (typeHasAssociations(assocType)) return (
            <Card body border='secondary' className='my-3' key={`assocBlock_${i}`}>
              <Card.Title>{toTitleCase(assocType)}</Card.Title>
              {other.filter(a => a.from.type === assocType).map((assoc, i) => 
                <Association showOnly={showOnly} association={assoc} key={`notvc_assoc_${i}`} assocApi={assocApi} setAlert={setAlert} />
              )}
            </Card>
          )
          return null;
        })}
      </EntityCard>

    </>
  );
};

export default Associations;
