import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useGenshinAuth } from '../../contexts/GenshinAuthContext';
import useUser from '../../contexts/useUser';

import {
  EntityAvatar,
} from '../common';

const ProfileToggler = ({ user }) => (<>
  <EntityAvatar
    src={user.avatarUrl}
    className='mr-2'
    size={30}
  />
  <span className='mr-2'>{user.username}</span>
</>);

const TopMenu = () => {
  const { links, logout } = useGenshinAuth();
  const loggedIn = useUser(state => state.loggedIn);
  const avatarUrl = useUser(state => state.avatarUrl);
  const username = useUser(state => state.username);

  return (
    <Navbar bg='dark' expand="lg">
        <Nav className="ml-auto">
          {loggedIn
            ? (
              <div>
                <Dropdown>
                  <Dropdown.Toggle className='bg-dark border-dark text-light d-flex align-items-center justify-content-start'>
                    <ProfileToggler user={{ avatarUrl, username }} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/profile" className='dropdown-item' role='button'>Profile</Link>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <Button
                variant='outline-light'
                href={links.login}
              >
                Log in with Discord
              </Button>
            )
          }
        </Nav>
    </Navbar>
  );
};

export default TopMenu;
