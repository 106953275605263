import create from 'zustand';

const useUser = create((set, get) => ({
  username: null,
  avatarUrl: null,
  locale: null,
  platform: null,
  accessToken: null,
  tokenType: null,

  editingOwn: false,
  initialized: false,
  loggedIn: false,

  permissions: [],
  topics: [],

  setUserData: userData => set(_ => ({ ...userData, initialized: true })),
  setPermissions: perm => set(_ => ({ permissions: perm.map(p => p.name) })),
  setTopics: ut => set(_ => ({ topics: [...ut] })),

  wipeUserData: () => set(_ => ({
    username: null,
    avatarUrl: null,
    locale: null,
    platform: null,
    accessToken: null,
    tokenType: null,

    editingOwn: false,
    initialized: false,
    loggedIn: false,

    permissions: [],
    topics: [],
  })),

  setEditingOwn: e => set(_ => ({ editingOwn: e })),
  setLoggedIn: l => set(_ => ({ loggedIn: l })),

  isAdmin: () => get().permissions?.includes('Administrator'),

  canAssociatePrimarySources: () => {
    return get().canEditPrimarySources() ||
      get().isAdmin() || false;
  },

  canDissociatePrimarySources: () => {
    return get().permissions?.includes('Deactivate associations') ||
      get().isAdmin() || false;
  },

  canCreateTopics: () => {
    return get().permissions?.includes('Create topics') ||
      get().isAdmin() || false;
  },

  canEditPrimarySources: () => {
    return get().permissions?.includes('Edit markdown notes') ||
      get().isAdmin() || false;
  },

  canManagePublishedTopics: () => {
    return get().permissions?.includes('Manage published topics') ||
      get().isAdmin() || false;
  },

  canAssociateToPublishedTopics: () => {
    return get().permissions?.includes('Associate to published topics') ||
      get().isAdmin() || false;
  },

  canPromoteUsers: () => {
    return get().permissions?.includes('Promote users') ||
      get().isAdmin() || false;
  },

  canEditAssociations: () => {
    return get().canAssociatePrimarySources() ||
      get().canDissociatePrimarySources() ||
      get().editingOwn
  },

  canEditKqm: () => {
    return get().permissions?.includes('Edit Kqm') ||
      get().isAdmin() || get().loggedIn;
  }

}));

export default useUser;
