import { useEffect } from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import useGetManyTopics from './hooks/useTopics/useGetManyTopics';
import useGetUserMetadata from './hooks/useGetUserMetadata';
import useEntityList from './hooks/useEntityList';
import useStore from './hooks/useStore';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ScrollToTop from './components/ScrollToTop';

import {
  TopBanner,
  TopMenu,
  PageMenu,
  Footer,
} from './wikiBuilder';
import { Researcher, Calendar, Profile, Articles } from './pages';

import { GenshinDataProvider } from './contexts/GenshinDataContext';
import { GenshinAuthProvider, Authenticator } from './contexts/GenshinAuthContext';
import useUser from './contexts/useUser';

const AppContent = () => {
  const { pathname } = useLocation();

  const {
    loggedIn,
    setPermissions,
    setUserTopics,
  } = useUser(state => ({
    loggedIn: state.loggedIn,
    setPermissions: state.setPermissions,
    setUserTopics: state.setTopics,
  }));

  const setEntityList = useStore(state => state.setEntityList);

  const {
    metadata: userMetadata,
    getMetadata,
  } = useGetUserMetadata();

  const {
    getTopics,
    topics,
  } = useGetManyTopics();

  const {
    entityList,
  } = useEntityList({ immediate: true });

  useEffect(async () => {
    if (!loggedIn) return;

    const succeeded = await getMetadata();
    if (succeeded) {
      getTopics();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!entityList.length) return;
    setEntityList(entityList);
  }, [entityList.length]);

  useEffect(() => {
    if (!topics?.length) return;
    setUserTopics(topics);
  }, [topics?.length]);

  useEffect(() => {
    if (!userMetadata?.permissions?.length) return;
    setPermissions(userMetadata.permissions);
  }, [userMetadata?.permissions?.length]);

  return (
    <Container className="bg-light shadow p-0 mb-5" fluid>
      <TopMenu />
      <TopBanner />
      <PageMenu />

      <Row className={'my-4 p-3'}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

          <Route path="/researcher">
            <Researcher />
          </Route>

          <Route path="/articles">
            <Articles />
          </Route>

          <Route path="/calendar">
            <Calendar />
          </Route>

          <Route path="/profile">
            <Profile />
          </Route>

          <Route path="/authenticate">
            <Authenticator />
          </Route>

          <Route path="/">
            <Redirect to="/researcher" />
          </Route>
        </Switch>
      </Row>

      <Footer />
    </Container>
  );
}


function App() {
  return (
    <GenshinAuthProvider>
      <GenshinDataProvider>
        <Router>
          <ScrollToTop />
          <Container className="bg-dark m-0 p-0 App" fluid>
            <Row>
              <Col sm={1} />
              <Col sm={10}>
                <AppContent />
              </Col>
              <Col sm={1} />
            </Row>
          </Container>
        </Router>
      </GenshinDataProvider>
    </GenshinAuthProvider>
  );
}

export default App;
