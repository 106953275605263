import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useUpdateExternalLore = () => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [external, setExternal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const updateExternal = useCallback(async (id, payload) => {
    try {
      const resource = await put(`/articles/${id}`, {
        data: {
          ...payload,
        },
      });
      if (response.ok && !error) {
        setExternal(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error updating external:', e, payload);
    }
  }, [response, put]);

  return {
    external,
    updateExternal,
    response,
    loading,
    error,
  };
}

export default useUpdateExternalLore;
