import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Check } from 'react-bootstrap-icons';

const InlineForm = ({
  initialValue,
  onClose,
  onSave,
  saving,
}) => {
  const [dirty, setDirty] = useState(initialValue);

  return (
    <div className='d-flex justify-content-between'>
      <FormControl
        value={dirty}
        onChange={e => setDirty(e.target.value)}
      />
      <ButtonToolbar className='ml-1 flex-nowrap align-items-center justify-content-center'>
        {saving
          ? <Spinner animation='border' size='sm' />
          : (
            <>
{/*              <Button
                variant='light'
                className='py-1 px-0'
                onClick={onClose}
              >
                <X size='25px' />
              </Button>*/}
              <Button
                variant='success'
                className='py-1 px-0'
                onClick={() => onSave(dirty)}
              >
                <Check size='25px' />
              </Button>
            </>
          )
        }
      </ButtonToolbar>
    </div>
  );
}

export default InlineForm;
