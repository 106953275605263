import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import {
  EntityCard,
  EntityCardTitle,
  EntityTable,
  EntityTableRow,
  ImageCard,
  LoreMissing,
  toUri,
  Aliases,
} from '../../wikiBuilder';

const Drop = ({ drop }) => (
  <EntityTableRow
    associable={drop.lore || drop.description}
    from={{
      data: drop,
      type: 'Item',
    }}
    title={drop.name}
    imageUrl={drop.imageUrl}
    htmlContent={drop.description}
    titleColSpan='4'
    linkTo={`/researcher/Item/${toUri(drop.name)}`}
  >
    {!drop.description && <LoreMissing />}
  </EntityTableRow>
);

export const MonsterIntro = ({
  resource: monster,
  editable,
  onSave,
  className,
}) => {
  const {
    name,
    family,
    title,
    specialTitle,
    imageUrl,
    aliases,
  } = monster;

  const hasSpecialTitle = specialTitle !== name;
  const hasTitle = title !== name;

  let displayTitle = title;
  if (hasSpecialTitle && hasTitle) {
    displayTitle = specialTitle;
  } else if (hasSpecialTitle) {
    displayTitle = specialTitle;
  } else if (hasTitle) {
    displayTitle = title;
  } else {
    displayTitle = undefined;
  }

  return (
    <Row>
      <Col md='6' className={'mb-3'}>
        <Card className={`p-0 ${className}`}>
          <Card.Header>
            <span className={'h2 mr-2 font-weight-bold'}>{name}</span>
            {displayTitle && <div className='h5'>{displayTitle}</div>}
            <Aliases
              aliases={aliases}
              editable={editable}
              onSave={onSave}
            />
          </Card.Header>
          <ListGroup className="list-group-flush">
            {family && 
              <ListGroupItem>
                <div><strong>Family: </strong>{family}</div>
              </ListGroupItem>
            }
          </ListGroup>
        </Card>
      </Col>

      <Col md='6' lg='5'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>
  );
}

const Monster = ({ resource: monster, setAlert }) => {
  const {
    lore,
    drops,
  } = monster;

  return (
    <div className='w-100'>
      <EntityCard
        associable
        from={{
          data: monster,
          type: 'Monster',
        }}
        Title={() => <EntityCardTitle title='Lore' />}
        htmlContent={lore}
      >
        {!lore && <LoreMissing />}
      </EntityCard>

      {drops?.length === 0 ? <p className='text-muted'>No item drops</p> : (
        <EntityCard
          Title={() => <EntityCardTitle title='Drops' />}
          bodyClasses='px-3'
        >
          <EntityTable
            titleColName='Drop'
            titleColSpan='4'
          >
            {drops?.map((drop, i) => {
              if (drop.imageUrl)
                return <Drop drop={drop} key={`drop_${i}`} />
              return null
            })}
          </EntityTable>
        </EntityCard>
      )}
    </div>
  );
}

export default Monster;
