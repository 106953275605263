import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import sanitizeHtml from 'sanitize-html';

import {
  EntityCard,
  EntityCardTitle,
  ListItemTitle,
  ImageCard,
  LoreMissing,
  toUri,
} from '../../wikiBuilder';

const Dropper = ({ monster }) => (
  <EntityCard
    associable
    from={{
      data: monster,
      type: 'Monster',
    }}
    Title={() => <ListItemTitle title={monster.name} imageUrl={monster.imageUrl} />}
    htmlContent={monster.lore}
    linkTo={`/researcher/Monster/${toUri(monster.name)}`}
    startClosed
  />
);

export const ItemIntro = ({
  resource: item,
  className,
}) => {
  const {
    name,
    uses,
    description,
    lore,
    imageUrl,
  } = item;

  return (
    <Row className='mb-5'>
      <Col md='6'>
        <Card className={`p-0 ${className}`}>
          <Card.Header className={'h2'}>
            <span className={'mr-2 font-weight-bold'}>{name}</span>
            <div className='h5'>
              {uses?.join(', ')}
            </div>
          </Card.Header>
          {lore &&
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <div
                  dangerouslySetInnerHTML={{ 
                    __html: sanitizeHtml(description, { 
                      allowedTags: ['p']
                    })
                  }}
                />
              </ListGroupItem>
            </ListGroup>
          }
        </Card>
      </Col>

      <Col md='6' lg='5'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>       
  );
}

const Item = ({ resource: item }) => {
  const {
    lore,
    description,
  } = item;

  return (
    <div className='w-100'>
      <EntityCard
        associable
        from={{
          data: item,
          type: 'Item',
        }}
        Title={() => <EntityCardTitle title='Lore' />}
        htmlContent={lore ? lore : description}
      >
        {!lore && !description && <LoreMissing />}
      </EntityCard>

      {item?.monsters?.length !== 0 && (
        <EntityCard
          Title={() => <EntityCardTitle title='Dropped By' />}
          bodyClasses='px-3'
        >
          {item.monsters?.map((monster, i) => {
            if (monster.imageUrl) return <Dropper monster={monster} key={`dropper_${i}`} />
            return null
          })}
        </EntityCard>
      )}
    </div>
  );
}

export default Item;
