import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import '../../App.css';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import { TagForm, Alert } from '../../wikiBuilder';

import ExternalLorePreview from './ExternalLorePreview';

import { X } from 'react-bootstrap-icons';

const NewExternalForm = ({
  createExternal,
  existingExternals,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState(null);
  const [dupSource, setDupSource] = useState(null);

  const handleToggle = useAccordionToggle('0', () => {
    setIsOpen(!isOpen);
  });

  const [showValidation, setShowValidation] = useState('');

  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [sources, setSources] = useState([]);
  const [newSourceName, setNewSourceName] = useState('');
  const [newSourceUrl, setNewSourceUrl] = useState('');

  const nameInvalid = () => name === '';
  const tagsInvalid = () => tags.length === 0;
  const sourcesInvalid = () => sources.length === 0;

  const handleAddSource = e => {
    e.preventDefault(); e.stopPropagation();
    let useUrl = newSourceUrl.trim();
    if (newSourceName === '' || newSourceUrl === '') return;
    
    if (useUrl.slice(0,8) !== 'https://') useUrl = 'https://' + useUrl;

    const dupe = existingExternals.find(ee => ee.sources.some(s => s.url === useUrl));
    if (dupe) {
      setDupSource(dupe);
      return;
    } else {
      setDupSource(null);
    }
    setSources([ ...sources, {
      name: newSourceName.trim(),
      url: useUrl,
    }]);
    setNewSourceName(''); setNewSourceUrl('');
  }

  const removeSource = i => {
    const newSources = [ ...sources ];
    newSources.splice(i, 1);
    setSources(newSources);
  }

  const handleCreateExternal = async () => {
    if (nameInvalid() || tagsInvalid() || sourcesInvalid()) {
      setShowValidation(true);
      return;
    }
    const succeeded = await createExternal({
      name,
      sources,
      tags,
    });
    if (succeeded) {
      setAlert({
        variant: 'success',
        message: 'Got it! Thanks for contributing! ^_^'
      });
      setNewSourceName('');
      setNewSourceUrl('');
      setName('');
      setTags([]);
      setSources([]);
      setShowValidation(false);
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  }

  return (
    <>
      <Accordion
        defaultActiveKey={undefined}
        className='my-3 w-100'
      >
        <Accordion.Toggle
          as={Button}
          className='float-right btn-light btn-outline-dark mb-2'
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onClick={handleToggle}
        >
          {isOpen ? 'Done' : 'Add External Lore'}
        </Accordion.Toggle>
        

        <Card
          className={`
            ${!isOpen && 'border-0'}
            w-100
          `}
          bg={'light'}
          text={'dark'}
        >
          <Accordion.Collapse eventKey='0'><>
            <Card.Header>Add to the Lore Library!</Card.Header>

            <Card.Body>
              <Form.Group className='mb-3' controlId='externalTitle'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='What is the Dark Sea'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className={showValidation && nameInvalid() && 'border-danger'}
                />
                {showValidation && nameInvalid() && <div className='text-danger'>Name can't be blank</div>}
              </Form.Group>

              <Form.Label>Sources</Form.Label>
                <Card body bg='light' className='mb-3'>
                  <div className={`d-flex flex-wrap`}>
                    {sources.map((source, i) => (
                      <Badge
                        variant="info"
                        pill
                        className='m-1 d-flex align-items-center justify-content-between '
                        key={`source_${i}`}
                      >
                        <p className={`m-0 d-inline-block px-1 text-left`} style={{ whiteSpace: 'pre-wrap' }}>{source.name}</p>
                        <Button
                          size='sm'
                          className='bg-transparent border-0 p-0'
                          onClick={() => removeSource(i)}>
                          <X />
                        </Button>
                      </Badge>
                    ))}
                  </div>
                  <Form onSubmit={handleAddSource}>
                    <Form.Label>Source Title</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Source Title'
                      value={newSourceName}
                      onChange={e => setNewSourceName(e.target.value)}
                      className={showValidation && sourcesInvalid() && 'border-danger'}

                    />
                    <Form.Label>Source URL</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='https://youtu.be/E32LI3X71jI'
                      value={newSourceUrl}
                      onChange={e => setNewSourceUrl(e.target.value)}
                      className={showValidation && sourcesInvalid() && 'border-danger'}
                    />
                    {showValidation && sourcesInvalid() && <div className='text-danger'>Must have at least one source</div>}
                    {dupSource && <div className='text-danger'>There is already an external with that source: {dupSource.name}</div>}

                    <Button variant='primary' type='submit' className='mt-3' disabled={!(newSourceName && newSourceUrl)}>
                      Add
                    </Button>
                  </Form>
                </Card>

              <Form.Group className='mb-3' controlId='tags'>
                <Form.Label>Tags</Form.Label>
                <TagForm
                  initialTags={tags}
                  onChange={tags => setTags(tags)}
                  badgeClasses='px-1'
                  inputClasses={showValidation && tagsInvalid() && 'border-danger'}
                />
                {showValidation && tagsInvalid() && <div className='text-danger'>We need tags to find this later!</div>}
              </Form.Group>

              <hr />

              <Form.Label>Preview</Form.Label>
              <ExternalLorePreview
                external={{
                  name,
                  sources,
                  tags,
                }}
              />

              <Alert alert={alert} setAlert={setAlert} />
              <Button
                variant='success'
                type='submit'
                onClick={handleCreateExternal}
              >
                Submit
              </Button>
            </Card.Body>
          </></Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
}

export default NewExternalForm;
