import { useState } from 'react';
import Badge from 'react-bootstrap/Badge';

/* https://github.com/ericgio/react-bootstrap-typeahead/blob/HEAD/docs/API.md#typeahead */
import { ClearButton, Typeahead, Highlighter } from 'react-bootstrap-typeahead';

import { compareName } from '../utils';

import { toTitleCase } from '../../wikiBuilder';

export const EntitySearchBar = ({
  onSelect,
  filter = [],
  entityList,
  ...rest
}) => {
  const [menuOpen, setMenuOpen] = useState(undefined);
  return (
    <Typeahead
      id="entity_searchbar"
      className={'w-100 my-1'}
      onChange={onSelect}
      onBlur={() => setMenuOpen(false)}
      onFocus={() => setMenuOpen(undefined)}
      isLoading={!entityList.length}
      open={menuOpen}
      labelKey='name'
      filterBy={(option, props) =>
        option.aliasNames.find(alias => alias.toLowerCase().includes(props.text.toLowerCase())) !== undefined
      }
      options={
        filter.length !== 0
        ? entityList.filter(entity => filter.includes(entity.type))
        : entityList
      }
      renderMenuItemChildren={(option, props, index) => {
        const aliases = option.aliasNames.filter(an => an !== option.name);
        return (
          <>
            <p className='m-0'>
              <Badge variant="light">{option.type}</Badge>
              <span>{option.name}</span>
            </p>
            {aliases && <p className='text-muted m-0'>
              <Highlighter search={props.text} highlightClassName='font-weight-bold'>
                {aliases.join(', ')}
              </Highlighter>
            </p>}
          </>
        )
      }}
      paginationText={'More...'}
      allowNew
      {...rest}
    >
      {({ onClear, selected }) => (
        <div className="rbt-aux">
          {!!selected.length && <ClearButton onClick={onClear} />}
        </div>
      )}
    </Typeahead>
  );
};

export const EntityTypeSearchBar = ({
  onChange,
  excludes = [],
  entityList,
  ...rest
}) => {

  return (
    <Typeahead
      id="entity_search_filter"
      className={'w-100 my-1'}
      options={Array.from(new Set(entityList.map(entity => toTitleCase(entity.type))))
        .filter(opt => !excludes.includes(opt))
      }
      onChange={onChange}
      isLoading={!entityList.length}
      {...rest}
    >
      {({ onClear, selected }) => (
        <div className="rbt-aux">
          {!!selected.length && <ClearButton onClick={onClear} />}
        </div>
      )}
    </Typeahead>
  );
}

const EntitySearch = ({ onSelect, onTypeSelect, entityList }) => {
  const [ filter, setFilter ] = useState([]);

  return (
    <>
      <EntityTypeSearchBar
        entityList={entityList}
        onChange={selected => {
          setFilter(selected);
          onTypeSelect(selected);
        }}
        size='lg'
        placeholder='Category'
        highlightOnlyResult
      />
      <EntitySearchBar
        onSelect={onSelect}
        entityList={entityList.sort(compareName)}
        filter={filter}
        size='lg'
        placeholder='Whatcha wanna know about?'
      />
    </>
  );
}

export default EntitySearch;