import Form from 'react-bootstrap/Form';

const GoodFaithCheckbox =({
  onClick,
}) => (
  <Form.Check 
    custom
    type={'checkbox'}
    onChange={(e) => {
      onClick(e.target.checked);
    }}
    id={`real_ack`}
    label={`You're about to contribute to Very Serious Research. Please check that this association is accurate and leave a meaningful note. Thank you! ${String.fromCodePoint(0x2764)}`}
  />
);

export default GoodFaithCheckbox;