import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';
import { urlEncode, encodeBase64 } from '@techteia/utility';

import { handleError } from '../utils';

const params = {
  filter: {
    dateInactive: null,
    datePublished: { $ne: null },
  },
  unset: ['dateInactive', ],
}

const useGetPublishedTopics = () => {
  const {
    get,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topics, setTopics] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const getTopics = useCallback(async (id) => {
    try {
      const resource = await get(`/topics?${urlEncode({ data: encodeBase64(JSON.stringify(params)) })}`);
      if (response.ok) {
        setTopics(resource);
      }
    } catch (e) {
      handleError('Error getting published topics:', e);
    }
  }, [response, get]);

  return {
    topics,
    getTopics,
    response,
    loading,
    error,
  };
}

export default useGetPublishedTopics;
