import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useGetManyExternalLores = () => {
  const {
    get,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [externals, setExternals] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const getExternals = useCallback(async () => {
    
    try {
      const resource = await get(`articles`);
      if (response.ok) {
        setExternals(resource);
      }
    } catch (e) {
      handleError('Error getting externals:', e);
    }
  }, [response, get]);

  return {
    externals,
    getExternals,
    response,
    loading,
    error,
  };
}

export default useGetManyExternalLores;