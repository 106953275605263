import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  ImageCard,
} from '../../wikiBuilder';

export const ElementIntro = ({
  resource: element,
  onSave,
  editable,
  setEditable,
  className,
}) => {
  const {
    imageUrl,
    name,
  } = element;

  return (
    <Row className='mb-3'>
      <Col md='9'>
        <Card className={`p-0 ${className}`}>
          <Card.Header>
            <span className={'h2 mr-2 font-weight-bold'}>{name}</span>
          </Card.Header>
        </Card>
      </Col>

      <Col md='3'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>       
  );
};

const Element = ({ resource: element }) => {
  return null;
}

export default Element;
