import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  EntityCard,
  EntityCardTitle,
  ImageCard,
  LoreMissing,
} from '../../wikiBuilder';

export const LoadingScreenIntro = ({
  resource: screen,
  className,
}) => {
  const {
    name,
    imageUrl,
  } = screen;

  return (
    <Row className='mb-3'>
      <Col md='9'>
        <Card className={`p-0 ${className}`}>
          <Card.Header className={'h2'}>
            <span className={'mr-2 font-weight-bold'}>{name}</span>
          </Card.Header>
        </Card>
      </Col>

      <Col md='3'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>       
  );
}

const LoadingScreen = ({ resource: screen }) => {
  const {
    lore,
  } = screen;

  return (
    <EntityCard
      associable
      from={{
        data: screen,
        type: 'LoadingScreen',
      }}
      Title={() => <EntityCardTitle title='Lore' />}
      htmlContent={lore}
    >
      {!lore && <LoreMissing />}
    </EntityCard>
  );
}

export default LoadingScreen;
