import { useRef, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import useUser from '../../contexts/useUser';

import {
  Link,
} from "react-router-dom";

const PageMenu = () => {
  const calendar = useRef(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const loggedIn = useUser(state => state.loggedIn);

  return (
    <Navbar bg="secondary" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link
            to="/researcher"
            title={"Lore research assistant"}
            className="text-light nav-link py-1"
          >
            Primary Sources
          </Link>
          <Link
            to="/articles"
            title={"Published lore writeups"}
            className="text-light nav-link py-1"
          >
            Articles
          </Link>
          {loggedIn &&
            <Link
              to="/profile"
              title={"My research topics"}
              className="text-light nav-link py-1"
            >
              My Topics
            </Link>
          }
          {false && <>
            <div
              ref={calendar}
              onMouseEnter={() => {
                setShowComingSoon(true);
              }}
              onPointerLeave={() => {
                setShowComingSoon(false);
              }}
            >
              <Link
                to="/calendar"
                className="text-light nav-link py-1 disabled"
                title="Interactive Genshin Calendar"
              >
                Calendar
              </Link>
            </div>}
            <Overlay target={calendar.current} show={showComingSoon} placement="top">
              {(props) => (
                <Tooltip {...props}>
                  Coming Soon
                </Tooltip>
              )}
            </Overlay>
          </>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default PageMenu;
