import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';

export const LoadingScreenListItem = ({ entity: screen, ...rest }) => {
  return (
    <EntityCard
      associable
      from={{
        data: screen,
        type: 'LoadingScreen',
      }}
      Title={() => <ListItemTitle
        imageUrl={screen.imageUrl}
        title={screen.name}
      />}
      htmlContent={screen.lore}
      startClosed
      {...rest}
    />
  );
};

export default LoadingScreenListItem;