import {
  EntityCard,
  ListItemTitle,
  LoreMissing,
} from '../../wikiBuilder';

const Volume = ({ volume, belongsTo, startClosed = false }) => {
  const {
    description,
    content,
    name,
    imageUrl,
  } = volume;

  return (
    <EntityCard
      associable
      from={{
        data: volume,
        type: 'BookVolume',
        belongsTo,
      }}
      Title={() => <ListItemTitle title={name} imageUrl={imageUrl} />}
      htmlIntro={description}
      htmlContent={content}
      startClosed={startClosed}
    >
      {!content && <LoreMissing />}
    </EntityCard>
  );
}

export default Volume;