import { useState, useEffect } from 'react';

import useCreateAssociation from './useCreateAssociation';
import useDeleteAssociation from './useDeleteAssociation';
import useGetManyAssociations from './useGetManyAssociations';
import useUpdateAssociation from './useUpdateAssociation';

const filterOutTopicsHiddenFromUser = (associations, userTopicIds) => {
  return associations.filter(a => {
    if (a.to.type !== 'Topic') return a;

    if (a.to._id.datePublished) return a;

    if (userTopicIds.includes(a.to._id._id)) return a;

    return undefined;
  }).filter(a => a);
};

const useAssociationList = (type, target) => {
  const [associations, setAssociations] = useState(null);
  const [userTopicIds, setUserTopicIds] = useState([]);

  const {
    getAssociations: _getAssociations,
    associations: initialAssociations,
    loading: fetching,
  } = useGetManyAssociations(type, target);

  const getAssociations = (id, topics) => {
    if (topics) setUserTopicIds(topics.map(t => t._id));
    _getAssociations(id);
  }

  const {
    createAssociation,
    association: newAssociation,
    loading: creating,
  } = useCreateAssociation();

  const {
    updateAssociation,
    association: updatedAssociation,
    loading: updating,
  } = useUpdateAssociation();

  const {
    deleteAssociation,
    association: deletedAssociation,
    loading: deleting,
  } = useDeleteAssociation();

  useEffect(() => {
    if (!initialAssociations) return;

    if (target === 'to') {
      setAssociations(initialAssociations);
      return;
    }

    setAssociations(
      filterOutTopicsHiddenFromUser(initialAssociations, userTopicIds)
    );
  }, [initialAssociations]);

  useEffect(() => {
    if (newAssociation)
      setAssociations([ ...associations, newAssociation]);
  }, [newAssociation]);

  useEffect(() => {
    if (updatedAssociation) {
      const newArr = [...associations];
      const changed = associations.find(a => a._id === updatedAssociation._id);
      const changedIdx = associations.findIndex(a => a._id === updatedAssociation._id);
      newArr.splice(changedIdx, 1, {
        ...changed,
        markdownNotes: updatedAssociation.markdownNotes,
        dateInactive: updatedAssociation.dateInactive,
      });
      setAssociations(newArr);
    }
  }, [updatedAssociation]);

  useEffect(() => {
    if (deletedAssociation)
      setAssociations(associations.filter(a => a._id !== deletedAssociation._id));
  }, [deletedAssociation]);

  return {
    associations,

    getAssociations,
    fetching,

    createAssociation,
    creating,

    updateAssociation,
    updating,

    deleteAssociation,
    deleting,
  }
};

export default useAssociationList;
