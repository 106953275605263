import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import sanitizeHtml from 'sanitize-html';

import {
  EntityCard,
  EntityCardTitle,
  LoreMissing,
  ImageCard,
  Star,
} from '../../wikiBuilder';

export const WeaponIntro = ({
  resource: weapon,
}) => {
  const {
    name,
    rarity,
    weaponType,
    description,
    secondaryStatType,
    specialAbility,
    imageUrl,
  } = weapon;

  return (
    <Row>
      <Col md='6'>
        <Card className={`p-0 mb-3`}>
          <Card.Header className={'h2'}>
            <span className={'mr-2 font-weight-bold'}>{name}</span>
            <div className={'d-inline-block'}>
              {!rarity ? <p className='h5 text-muted'>Rarity unknown</p> : (
                [...Array(rarity)].map((_, i) => <Star
                  key={i}
                />)
              )}
            </div>
            {weaponType && (
                <div className='h5'>
                  {weaponType.name}
                </div>
              )}
          </Card.Header>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              {description ? <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
              /> : <div className={'h5 text-muted'}>No description yet</div> }
            </ListGroupItem>

            {(specialAbility || secondaryStatType) && 
              <ListGroupItem>
                {specialAbility && (
                  <p className={''}><strong>Special Ability: </strong>{specialAbility}</p>
                )}
                {secondaryStatType && (
                  <p className={''}><strong>Secondary Stat: </strong>{secondaryStatType}</p>
                )}
              </ListGroupItem>
            }
          </ListGroup>
        </Card>
      </Col>
      <Col xl='4' lg='5' md='6'>
        <ImageCard className={'mb-3'} url={imageUrl} />
      </Col>
    </Row>
  );
}

const Weapon = ({ resource: weapon }) => {
  const {
    lore,
  } = weapon;
  return (
    <div className='w-100'>
      <EntityCard
        associable
        from={{
          data: weapon,
          type: 'Weapon',
        }}
        Title={() => <EntityCardTitle title='Lore' />}
        htmlContent={lore}
      >
        {!lore && <LoreMissing />}
      </EntityCard>
    </div>
  );
}

export default Weapon;
