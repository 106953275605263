import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';

const RegionListItem = ({ entity: region, ...rest }) => (
  <EntityCard
    Title={() => <ListItemTitle
      imageUrl={region.imageUrl}
      title={region.name}
    />}
    startClosed
    {...rest}
  />
);

export default RegionListItem;