import React, { useState, useRef } from 'react';

import Form from 'react-bootstrap/Form';

import GoodFaithCheckbox from './GoodFaithCheckbox';
import CreateAssociationActions from './CreateAssociationActions';
import TargetTypeSelector from './TargetTypeSelector';
import useUser from '../../../contexts/useUser';

const generateBasicSlate = (text) => JSON.stringify([
  {
    type: 'paragraph',
    children: [{ text }],
  },
]);

const NewAssociationForm = ({
  from,
  onCreateSuccess,
  onCreateFail,
  onClose,
  assocApi,
  className,
}) => {

  const {
    createAssociation,
    creating,
    associations,
  } = assocApi;

  const [to, setTo] = useState(undefined);
  const [notes, setNotes] = useState('');
  const [ack, setAck] = useState(false);

  const submitButton = useRef(null);
  const userTopics = useUser(state => state.topics);

  const onSelect = (selected) => {
    setTo(selected[0]);
  };

  const onSubmit = async () => {
    const succeeded = await createAssociation({
      to: {
        _id: to._id,
        type: to.type,
      },
      from: {
        _id: from.data._id,
        type: from.type || from.data.type,
      },
      markdownNotes: generateBasicSlate(notes),
    });

    if (succeeded) {
      onCreateSuccess();
    } else {
      onCreateFail();
    }
  };

  return (
    <Form
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={className}
    >
      <TargetTypeSelector
        from={from}
        onSelect={onSelect}
        topics={userTopics}
        existingAssociations={associations}
      />
      <Form.Group controlId="newAssocForm.From">
        <Form.Label className='font-weight-bold'>From</Form.Label>
        <Form.Control
          type="text"
          value={`${from.belongsTo?.name ? '['+from.belongsTo?.name+'] ' : ''}${from.data.name}`}
          readOnly
        />
      </Form.Group>
      <Form.Group controlId="newAssocForm.notes">
        <Form.Label className='font-weight-bold'>Notes (optional)</Form.Label>
        <Form.Control 
          as="textarea" 
          rows={5}
          onChange={e => setNotes(e.target.value)}
          placeholder={'If it\'s not obvious, then please describe how they\'re related.'}
          style={{ resize: 'none' }}
        />
      </Form.Group>

      {(to && to.type !== 'Topic') && <GoodFaithCheckbox onClick={setAck} />}
      <CreateAssociationActions
        buttonRef={submitButton}
        to={to}
        ack={(to && to.type !== 'Topic') ? ack : true}
        loading={creating}
        onCreate={onSubmit}
        onCancel={onClose}
      />
    </Form>
  );
};

export default NewAssociationForm;
