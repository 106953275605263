import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';

import Artifact, { compareArtifacts } from './Artifact'; 

const ArtifactSetListItem = ({ entity: artifactSet, ...rest }) => {
  return (
     <EntityCard
        Title={() => <ListItemTitle title={artifactSet.name} imageUrl={artifactSet.artifacts[0].imageUrl} />}
        startClosed
        {...rest}
      >
        {artifactSet.artifacts?.sort(compareArtifacts)?.map(
          (artifact, i) => <Artifact artifact={artifact} belongsTo={artifactSet} key={`artifact_${i}`} startClosed />
        )}
      </EntityCard>
  );
};

export default ArtifactSetListItem;