import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useCreateTopic = () => {
  const {
    post,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topic, setTopic] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const createTopic = useCallback(async (articleTitle) => {
    try {
      const resource = await post('/topics', {
        data : {
          name: articleTitle,
          articleTitle,
          updatedBy: 'genshin-lore',
        },
      });
      if (response.ok) {
        setTopic(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error creating topic:', e, articleTitle);
    }
  }, [response, post]);

  return {
    topic,
    createTopic,
    response,
    loading,
    error,
  };
}

export default useCreateTopic;
