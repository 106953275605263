import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';

const ElementListItem = ({ entity: element, ...rest }) => (
  <EntityCard
    Title={() => <ListItemTitle
      imageUrl={element.imageUrl}
      title={element.name}
    />}
    startClosed
    {...rest}
  />
);

export default ElementListItem;