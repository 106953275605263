import {
  EntityCard,
  ListItemTitle,
  LoreMissing,
} from '../../wikiBuilder';

const WeaponListItem = ({ entity: weapon, ...rest }) => {
  return (
     <EntityCard
        associable
        from={{
          data: weapon,
          type: 'Weapon',
        }}
        Title={() => <ListItemTitle
          imageUrl={weapon.imageUrl}
          title={weapon.name}
        />}
        startClosed
        {...rest}
      >
        {weapon.lore
          ? <div dangerouslySetInnerHTML={{ __html: weapon.lore }} />
          : <LoreMissing />
        }
      </EntityCard>
  );
};

export default WeaponListItem;