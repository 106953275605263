import {
  EntityCard,
  ListItemTitle,
  EntityBadge,
  LoreMissing,
  toUri,
} from '../../wikiBuilder';

import { Link } from 'react-router-dom';

const MonsterListItem = ({ entity: monster, ...rest }) => {
  return (
     <EntityCard
        associable
        from={{
          data: monster,
          type: 'Monster',
        }}
        Title={() => <ListItemTitle
          imageUrl={monster.imageUrl}
          title={monster.name}
        />}
        startClosed
        {...rest}
      >
        {monster.lore
          ? (
            <div>
              <div dangerouslySetInnerHTML={{ __html: monster.lore }} />
              {monster?.drops?.length !== 0 && (
                <div className='text-muted'>
                  Drops: {monster?.drops.map((drop, i) => {
                    if (drop.imageUrl) {
                      return (
                        <Link
                          to={`/researcher/${drop.type}/${toUri(drop.name)}`}
                          role='button'
                          key={`drop_${i}`}
                        >
                          <EntityBadge
                            imageUrl={drop.imageUrl}
                            name={drop.name}
                          />
                        </Link>
                    )}
                    return null;
                  })}
                </div>
              )}
            </div>
          ) : <LoreMissing />
        }
      </EntityCard>
  );
};

export default MonsterListItem;