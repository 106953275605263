import { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { CursorFill } from 'react-bootstrap-icons';

import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";

import {
  Entity,
  EntityList,
  EntitySearch,
} from '../components';

import {
  SectionBreak,
  FrontPage,
  HtmlHead,
  Sidebar,
  toUri,
  fromUri,
} from '../wikiBuilder';

import { fromTargets } from '../components/entityConfig';

import useUser from '../contexts/useUser';
import { useGenshinAuth } from '../contexts/GenshinAuthContext';

import useStore from '../hooks/useStore';

const LoginPrompt = () => {
  const { links } = useGenshinAuth();

  return (
    <div className='m-3 w-100 d-flex flex-column justify-content-center align-items-center'>
      <a href={links.login}>
        <div className='btn btn-electro btn-lg'>
          Log in to create custom research results
        </div>
      </a>
      <p>(We use Discord, so no new account!)</p>
    </div>
  )
}

const PrimarySourceRouter = ({
  type,
  setType,
  entity,
  setEntity,
  entityList,
}) => {
  const { pathname } = useLocation();
  const { path: matchPath } = useRouteMatch();
  const loggedIn = useUser(state => state.loggedIn);

  useEffect(() => {
    if (!pathname) return;
    const pathPieces = pathname.split('/');

    if (pathPieces.length === 2) {
      setType(undefined);
      setEntity(undefined);
      return;
    }

    if (pathPieces.length === 3) { // is index path
      setType(pathPieces[2]);
    }

    if (entityList.length && pathPieces[3]) { // is entity path
      setEntity(entityList.find(en => 
        en.name === fromUri(pathPieces[3])
      ));
    } else {
      setEntity(undefined);
    }
  }, [pathname, entityList, setEntity, setType]);

  return (
    <>
    {loggedIn && fromTargets.includes(type) && (
      <div className='w-100 d-flex align-items-end justify-content-end'>
        <Toast className=''>
          <Toast.Body>
            Use the <CursorFill size={12} /> button to associate that primary source to one of your topics.
          </Toast.Body>
        </Toast>
      </div>
    )}
    <Switch>
      <Route path={`${matchPath}/undefined/:entityName`}>
        {loggedIn
          ? <Redirect to="/profile" />
          : <LoginPrompt />
        }
      </Route>

      <Route path={`${matchPath}/:entityType/:entityName`}>
        {entity
          ? <Entity entity={entity} />
          : <Spinner animation='border' />
        }
      </Route>

      <Route path={`${matchPath}/:entityType/`}>
        {type
          ? <EntityList type={type} />
          : <Spinner animation='border' />
        }
      </Route>

      <Route path={matchPath}>
        <FrontPage />
      </Route>

    </Switch>
    </>
  );
}

const Researcher = () => {
  const { url: matchUrl, } = useRouteMatch();
  let history = useHistory();

  const [type, setType] = useState(undefined);
  const [entity, setEntity] = useState(undefined);

  const entityList = useStore(state => state.entityList);

  const handleEntitySelect = (selected) => {
    if (selected.length === 0) {
      if (type) {
        history.push(`${matchUrl}/${type}`);
      } else {
        history.push(matchUrl);
      }
      return;
    }
    history.push(`${matchUrl}/${selected[0].type}/${toUri(selected[0].name)}`);
  }

  const handleTypeSelect = (selected) => {
    const toType = (str) => str.replace(/( )/g,'')
    if (selected.length === 0) {
      if (!entity) {
        history.push(matchUrl);
      } else {
        setType(undefined);
      }
      return;
    }
    history.push(`${matchUrl}/${toType(selected[0])}`);
  }

  return (
    <>
      <HtmlHead title={entity?.name || 'Researcher'} />

      <Col lg={9}>
        <Row className={'mx-1 mb-3'}>
          <EntitySearch
            onSelect={handleEntitySelect}
            onTypeSelect={handleTypeSelect}
            entityList={entityList}
          />
        </Row>

        <Row className={'mx-1 mt-3'}>
          <SectionBreak />
          <PrimarySourceRouter
            type={type}
            setType={setType}
            entity={entity}
            setEntity={setEntity}
            entityList={entityList}
          />
        </Row>
      </Col>

      <Col lg={3} sm='6'>
        <Sidebar />
      </Col>
    </>
  );
}

export default Researcher;
