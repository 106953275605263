import React, { createContext, useEffect, useState, useContext } from 'react';
import { useFetch } from 'use-http';
import { useLocation, useHistory } from "react-router-dom";
// import { CookiesProvider, useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

import useUser from './useUser';

const GenshinAuth = createContext();

const authUri = process.env.REACT_APP_AUTH_SERVER_URI;
// const loginUri = 'https://auth.genshinlore.com/api/discord/login';
// const refreshUri = 'https://auth.genshinlore.com/api/discord/refresh';
// const authUri = 'http://192.168.1.75:3501/api/discord';
const loginUri = `${authUri}/login`;

// const mockUser = {
//   accessToken: "Hu3OxlSPvuLEavAuD0mMMMt9JdaKbz",
//   avatarUrl: "https://cdn.discordapp.com/avatars/570069579225759749/29a7fe6fcea8b2854a5e7481a6d38188",
//   discriminator: "0878",
//   locale: "en-US",
//   platform: "discord",
//   tokenExpirationDate: "Tue May 18 2021 05:13:40 GMT+0000 (Coordinated Universal Time)",
//   tokenScope: "identify email",
//   tokenType: "Bearer",
//   username: "Wiggenwell",
//   usernameFull: "Wiggenwell#0878",
// };

const userFields = [
  'platform',
  'accessToken',
  'tokenExpirationDate',
  'tokenScope',
  'tokenType',
  'avatarUrl',
  'locale',
  'username',
  'discriminator',
  'usernameFull',
];

export const GenshinAuthProvider = ({ children }) => {
  const setUserData = useUser(state => state.setUserData);
  const wipeUserData = useUser(state => state.wipeUserData);

  const [cookiesAreFresh, setFresh] = useState(false);
  const [shouldBeLoggedIn, setShouldBeLoggedIn] = useState(true);
  const { post, response } = useFetch(authUri);
  const cookies = new Cookies();

  useEffect(() => {
    const userCookies = cookies.get('user');
    if (!userCookies || userCookies === 'undefined') return;

    if (cookiesAreFresh) {
      setUserData(userCookies);
    } else if (shouldBeLoggedIn) {
      refreshLogin();
    }
  }, [cookiesAreFresh, shouldBeLoggedIn]);

  const login = (newUser) => {
    cookies.set('user', JSON.stringify(newUser), { path: '/' });
    setFresh(true);
  };

  const forgetLogin = () => {
    cookies.remove('user');
    wipeUserData();
    setShouldBeLoggedIn(false);
  }

  const logout = async () => {
    const userCookies = cookies.get('user');

    try {
      await post('revoke', {
        accessToken: userCookies.accessToken,
      });

      if (response.ok) {
        forgetLogin();
      } else {
        console.error(response);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const refreshLogin = async () => {
    const userCookies = cookies.get('user');

    if (new Date(Date.now()) < (new Date(userCookies.tokenExpirationDate)-1)) {
      setFresh(true);
      console.log('not time to refresh login');
      return;
    };

    console.log('refreshed login');
    try {
      const resource = await post('refresh', {
        accessToken: userCookies.accessToken,
      });
      if (response.ok) {

        const newCookies = {
          ...userCookies,
          accessToken: resource.discord.token.access,
          tokenExpirationDate: resource.discord.token.expirationDate,
          tokenType: resource.discord.token.type,
          platform: resource.discord.token.platform,
        }
        login(newCookies);
      } else if (response.status === 401) {
        forgetLogin();
      } else {
        console.error(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GenshinAuth.Provider value={{
      links: {
        login: loginUri,
      },
      login,
      refreshLogin,
      forgetLogin,
      logout,
    }}>
      {children}
    </GenshinAuth.Provider>
  );
};

export const useGenshinAuth = () => {
  const genshinAuth = useContext(GenshinAuth);
  if (!genshinAuth) {
    throw new Error('genshinAuth context provider not found');
  }
  return genshinAuth;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Authenticator = () => {
  const { login } = useGenshinAuth();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const userObj = {};
    userFields.forEach(f => { userObj[f] = query.get(f) });
    login(userObj);
    history.push('/profile');
  }, []);
  return null;
}

export default GenshinAuth;
