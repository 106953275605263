import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { X } from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';

const TagForm = ({
  initialTags,
  onChange,
  badgeClasses,
  inputClasses,
}) => {
  const [tags, setTags] = useState(initialTags);
  const [currentTag, setCurrentTag] = useState('');
  
  const removeTag = (i) => {
    const newTags = [ ...tags ];
    newTags.splice(i, 1);
    onChange(newTags);
    setTags(newTags);
  };

  const addTag = (added) => {
    onChange([ ...tags, ...added.split(', ')]);
    setTags([ ...tags, ...added.split(', ')]);
  };

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && currentTag) {
      if (tags.find(tag => tag.toLowerCase() === currentTag.toLowerCase())) {
        return;
      }
      addTag(currentTag);
      setCurrentTag('');
    };
  };

  return (
    <div>
      <div className={`d-flex flex-wrap`}>
        {tags.map((tag, i) => (
          <Badge
            variant="info"
            pill
            className='m-1 d-flex align-items-center justify-content-between'
            key={tag}
          >
            <p className={`m-0 d-inline-block text-left ${badgeClasses}`} style={{ whiteSpace: 'pre-wrap' }}>{tag}</p>
            <Button
              size='sm'
              className='bg-transparent border-0 p-0'
              onClick={() => removeTag(i)}>
              <X />
            </Button>
          </Badge>
        ))}
      </div>
      <FormControl
        value={currentTag}
        onChange={e => setCurrentTag(e.target.value)}
        onKeyDown={handleKeyDown}
        className={inputClasses}
      />
    </div>
  );
}

export default TagForm;
