import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import FormControl from 'react-bootstrap/FormControl';

const DeleteConfirmationModal = ({
  association,
  isOpen,
  onClose,
  onDeleteSuccess,
  onDeleteFail,
  assocApi,
}) => {
  const [confirmationInput, setConfirmationInput] = useState('');

  const {
    deleteAssociation,
    deleting,
  } = assocApi;

  const handleDelete = async () => {
    const succeeded = await deleteAssociation(association._id);
    if (succeeded) {
      setConfirmationInput('');
      onClose();
    } else {
      onDeleteFail?.();
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Type <strong>{association.from._id.name}</strong> to confirm deletion.</p>
        <FormControl
          value={confirmationInput}
          placeholder={association.from._id.name}
          onChange={e => setConfirmationInput(e.target.value.trim())}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDelete}
          disabled={association.from._id.name !== confirmationInput}
        >
          {deleting
            ? <Spinner animation='border' size='sm' />
            : <span>Delete</span>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
