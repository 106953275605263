
import { associationPresenter } from '../../components/entityConfig';

import {
  AssociationCard,
  LoreMissing,
  ListItemTitle,
} from '../../wikiBuilder';

const Association = ({ showOnly, association, assocApi, setAlert }) => {
  const {
    title,
    imageUrl,
    intro,
    content,
    loreMissing,
    notes,
    showPageUri,
  } = associationPresenter(association);

  return (
    <AssociationCard
      showOnly={showOnly}
      Title={() => 
        <ListItemTitle
          title={title}
          imageUrl={imageUrl}
        />
      }
      htmlIntro={intro}
      htmlContent={content}
      notes={notes}
      association={association}
      assocApi={assocApi}
      setAlert={setAlert}
      linkTo={showPageUri}
    >
      {loreMissing && <LoreMissing />}
    </AssociationCard>
  );
}

export default Association;