import Artifact, { compareArtifacts } from './Artifact'; 

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  EntityAvatar,
} from '../../wikiBuilder';

export const ArtifactSetIntro = ({
  resource: artifactSet,
}) => {
  const {
    name,
    artifacts,
  } = artifactSet;

  return (
    <Row className='mb-5'>
      <Col md='6'>
        <Card className={`p-0 mb-3`}>
          <Card.Header className={'h2'}>
            <span className={'mr-2 font-weight-bold'}>{name}</span>
          </Card.Header>
        </Card>
      </Col>
      <Col xl='5' md='6'>
        <Card body>
          <div className='d-flex flex-wrap justify-content-center'>
            {artifacts?.sort(compareArtifacts)?.map(
              (a, i) => <EntityAvatar
                size='60px'
                src={a.imageUrl}
                className='m-2'
                key={`artifact_sea_image_${i}`}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
}

const ArtifactSet = ({ resource: artifactSet }) => {
  const { artifacts } = artifactSet;

  return (
    <div className='w-100'>
      {artifacts?.sort(compareArtifacts)?.map(
        (artifact, i) => <Artifact
          artifact={artifact}
          belongsTo={artifactSet}
          key={`artifact_${i}`}
        />
      )}
    </div>
  );
}

export default ArtifactSet;