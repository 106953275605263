import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const TopBanner = () => {

  return (
    <Row className={'bg-dark m-0 p-3 text-light'}>
      <Col sm='6'>
          <p className='mb-2'>Genshin Impact™ is a registered trademark of MiHoYo Co., Ltd.</p>
          <p className='mt-2'>Made by a fan for fans. Avid fans. Who like to theorize.</p>
      </Col>

      <Col sm='6'>
        <div>Many thanks to the data sources!</div>
        <div>&emsp;
          <a
            className='text-light'
            href='https://genshin-impact.fandom.com/wiki/Genshin_Impact_Wiki'
            title='Genshin Fandom link'
            target='_blank'
            rel='noreferrer'
          >
            Genshin-fandom
          </a>
        </div>
        <div>&emsp;
          <a
            className='text-light'
            href='https://genshin.honeyhunterworld.com/'
            title='Honey Impact link'
            target='_blank'
            rel='noreferrer'
          >
            Honey Impact
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default TopBanner;