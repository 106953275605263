const SectionBreak = ({ className}) => (
  <div
    className={`w-100 shadow-lg mb-3 ${className}`}
    style={{
      backgroundColor: 'grey',
      height: 1
    }}
  ></div>
);

export default SectionBreak;
