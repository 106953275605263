import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { handleError } from '../utils';

const useGetManyTopics = () => {
  const {
    get,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [topics, setTopics] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const getTopics = useCallback(async () => {
    try {
      const resource = await get(`/users/@me/topics`);
      if (response.ok) {
        setTopics(resource);
      }
    } catch (e) {
      handleError('Error getting user topics:', e);
    }
  }, [response, get]);

  return {
    topics,
    getTopics,
    response,
    loading,
    error,
  };
}

export default useGetManyTopics;