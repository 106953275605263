import React, { createContext, useEffect, useContext, useState } from 'react';
import { Provider as HttpProvider } from 'use-http';

import useUser from './useUser';

const GenshinData = createContext();

// const baseUri = 'http://192.168.1.75:3500/api';
const baseUri = process.env.REACT_APP_DATA_SERVER_URI;

export const GenshinDataProvider = ({ children }) => {
  const {
    userInitialized,
    tokenType,
    accessToken,
    platform,
    setLoggedIn,
  } = useUser(state => ({
    userInitialized: state.initialized,
    tokenType: state.tokenType,
    accessToken: state.accessToken,
    platform: state.platform,
    setLoggedIn: state.setLoggedIn,
  }));

  const [httpOptions, setHttpOptions] = useState({
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (!userInitialized) return;
    setHttpOptions({
      ...httpOptions,
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
        'Authentication-Platform': platform,
      },
    });
  }, [userInitialized]);


  useEffect(() => {
    if (!httpOptions.headers?.Authorization) return;
    setLoggedIn(true);
  }, [httpOptions?.headers?.Authorization]);

  return (
    <GenshinData.Provider value={{
      baseUri,
      httpOptions,
    }}>
      <HttpProvider
        url={baseUri}
        options={httpOptions}
      >
        {children}
      </HttpProvider>
    </GenshinData.Provider>
  );
};

export const useGenshinData = () => {
  const genshinApi = useContext(GenshinData);
  if (!genshinApi) {
    throw new Error('genshinApi context provider not found');
  }
  return genshinApi;
};

export default GenshinData;
