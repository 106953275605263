import {
  EntityCard,
  ListItemTitle,
} from '../../wikiBuilder';
import { compareOrder } from '../utils';

import Volume from './Volume';

const BookListItem = ({ entity: book, ...rest }) => {
  const { name, imageUrl } = book;

  return (
    <EntityCard
      Title={() => <ListItemTitle
          imageUrl={imageUrl}
          title={name}
        />}
      startClosed
      {...rest}
    >
      {book.volumes?.sort(compareOrder)?.map(
        (volume, i) => <Volume
          volume={{ ...volume, imageUrl }}
          belongsTo={book}
          key={`volume_${i}`}
          startClosed
        />
      )}
    </EntityCard>
  );
};

export default BookListItem;