import React, { useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';

import { ThreeDots } from 'react-bootstrap-icons';

import EditAssociationsMenu from './EditAssociationsMenu';
import DeleteConfirmationModal from './DeleteConfirmationModal';

export const EditAssociationButton = ({
  className,
  association,
  setEditable,
  editable,
  assocApi,
  onDeleteFail,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const popover = useRef(null);

  return (
    <>
      {!editable && (
          <Button
            ref={popover}
            variant='light'
            title={`manage this link from ${association.from._id.name} to ${association.to._id.name}`}
            className={`${className} float-right px-2 pb-1 pt-0`}
            onClick={(e) => {
              e.stopPropagation();
              setShowForm(!showForm);
            }}
            onBlur={() => setShowForm(false)}
          >
            <ThreeDots />
          </Button>
        )
      }
 
      <EditAssociationsMenu
        overlayTarget={popover.current}
        isOpen={showForm}
        onEdit={() => setEditable(true)}
        onDelete={() => setConfirmationModalOpen(true)}
      />

      <DeleteConfirmationModal
        association={association}
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onDeleteFail={onDeleteFail}
        assocApi={assocApi}
      />
    </>
  );
};

export default EditAssociationButton;