
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';

import bgImg from '../../images/background.jpg';

const TopBanner = () => {

  return (
    <div className='position-relative'>
      <Image id='banner-img' src={bgImg} fluid />
      <h1 className='my-4 banner-title'>
        <Badge variant='dark' style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 7,
          borderBottomLeftRadius: 7,
        }}>Genshin Lore</Badge>
        <p className='h5 text-right mr-3 text-dark'>
          Powered by&nbsp;
          <a
            href='https://genshin.honeyhunterworld.com/'
            title='Honey Impact link'
            target='_blank'
            rel='noreferrer'
          >
            Honey Impact
          </a>
        </p>
      </h1>
    </div>
  );
};

export default TopBanner;