import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { getEntityRoute } from '../../components/entityConfig';

import { handleError } from '../utils';

const useUpdateEntity = (type, initialValue = null) => {
  const {
    put,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [entity, setEntity] = useState(initialValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

    // PUT pass
  // aliases: [String]
  // updateAliases: Bool (true to add, false to remove)
  // markdownNotes

  const updateEntity = useCallback(async (id, payload) => {
    let url = `${getEntityRoute(type)}/${id}`;

    try {
      const resource = await put(url, {
        data: {
          ...payload,
          updatedBy: 'genshin-lore',
        }
      });
      if (response.ok && !error) {
        setEntity(resource);
        return true;
      } else {
        setError(resource);
        return false;
      }
    } catch (e) {
      handleError('Error updating resource:', e, { type, payload });
    }
  }, [response, put, type]);

  return {
    entity,
    updateEntity,
    response,
    loading,
    error,
  };
};

export default useUpdateEntity;
