import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import Badge from 'react-bootstrap/Badge';
import { PencilFill } from 'react-bootstrap-icons';

import {
  useHistory,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";

import {
  fromUri,
  toUri,
  RichTextBox,
  Aliases,
  Alert,
  HtmlHead,
} from '../../wikiBuilder';

import Associations from '../association/Associations';
import AddAssociationsButton from '../association/creation/AddAssociationsButton';

import useUser from '../../contexts/useUser';
import useGetTopic from '../../hooks/useTopics/useGetTopic';
import DeleteTopicButton from './DeleteTopicButton';
import PublishTopicButton from './PublishTopicButton';
import TopicTitle from './TopicTitle';
import useAssociationList from '../../hooks/useAssociations/useAssociationList';

const Topic = ({ showOnly, topicApi, backUrl }) => {
  const { topicTitle } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    topic,
    getTopic,
  } = useGetTopic();

  const canManagePublishedTopics = useUser(state => state.canManagePublishedTopics);

  const [alert, setAlert] = useState(null);
  const [editingNotes, setEditingNotes] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [refreshAssociationCount, setRefreshAssociationCount] = useState(0);
  const [dirtyTags, setDirtyTags] = useState(topic?.aliases);
  const [dirtyCategories, setDirtyCategories] = useState(topic?.categories);
  const [dirtyNotes, setDirtyNotes] = useState(null);

  const {
    topics,
    deleteTopic,
    deleting,

    updateTopic,
    updating,
  } = topicApi;

  const assocApi = useAssociationList('Topic', 'from');
  const {
    getAssociations,
  } = assocApi;

  useEffect(() => {
    if (!(topics?.length && topicTitle)) return;
    const thisTopic = topics.find(t => t.articleTitle === fromUri(topicTitle));
    getTopic(thisTopic._id);
    getAssociations(thisTopic._id);
  }, [topics, topicTitle]);

  const handleSave = async (payload) => {
    const succeeded = await updateTopic(topic._id, payload);
    if (succeeded) {
      if (payload.articleTitle && payload.articleTitle !== fromUri(topicTitle)) {
        history.replace(pathname.replace(topicTitle, toUri(payload.articleTitle)))
      }
      setAlert({
        variant: 'success',
        message: 'Topic updated!'
      });
      setRefreshAssociationCount(refreshAssociationCount+1);
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  };

  if (!topic) return <Spinner animation='border' />;

  return (
    <div>
      <HtmlHead topic={topic} />

      <Alert alert={alert} setAlert={setAlert} />

      {!showOnly && 
        <div className='w-100 d-flex align-items-end justify-content-end'>
          <Toast>
            <Toast.Body>
              Add <strong>search terms</strong> in the &nbsp;
              <Button variant='light' size='sm' className='pb-1 pt-0'>
                <PencilFill />
              </Button>
              &nbsp;menu to autoassociate more primary sources, or go to the 
              &nbsp;<Link to='/researcher'>researcher</Link> to  
              manually associate them.
            </Toast.Body>
          </Toast>
        </div>
      }

      {!showOnly &&
        <Row>
          <Col className='mb-2 d-flex justify-content-end align-items-center float-right'>
            <Button
              variant='link'
              onClick={async () => {
                if (editingNotes && dirtyNotes) await handleSave({
                  markdownNotes: JSON.stringify(dirtyNotes),
                });
                setEditingNotes(!editingNotes);
              }}
              className={editingNotes && 'text-danger'}
            >
              {editingNotes ? 'Done' : 'Edit'}
            </Button>
            <Button
              variant='light'
              className={`px-0 ${!updating && 'invisible'}`}
            >
              <Spinner animation='border' size='sm' className='text-secondary' />
            </Button>
          </Col>
        </Row>
      }

      <Row className='d-flex align-items-end justify-content-between'>
        <Col md='9'>
          <div className='mx-3'>
            {!editingTitle && <div>
              {topic.categories.map(cat => (
                <Badge pill variant='info' className='m-1'>
                  {cat}
                </Badge>
              ))}
            </div>}
            <TopicTitle
              showOnly={showOnly}
              title={topic.articleTitle}
              editable={editingTitle}
              saving={updating}
              setEditable={v => setEditingTitle(v)}
              onSave={async value => {
                await handleSave({
                  articleTitle: value,
                  aliases: dirtyTags,
                  categories: dirtyCategories,
                });
                setEditingTitle(false);
              }}
            />
            <Aliases
              aliases={topic.aliases}
              editable={editingTitle}
              onSave={value => setDirtyTags(value)}
              label={'Search terms'}
            />
            {editingTitle && <Aliases
              aliases={topic.categories}
              editable={editingTitle}
              onSave={value => setDirtyCategories(value)}
              label={'Categories'}
            />}
          </div>
        </Col>
        <Col md='1'>
          <AddAssociationsButton
            from={{ data: topic }}
            assocApi={assocApi}
          />
        </Col>
      </Row>
      <hr />

      <Card className={`p-0 bg-transparent border-0 m-3`}>
        <RichTextBox
          value={JSON.parse(topic.markdownNotes)}
          editable={editingNotes}
          onCancel={() => setEditingNotes(false)}
          onSave={(value) => handleSave({
            markdownNotes: JSON.stringify(value),
          })}
          onChange={(v) => setDirtyNotes(v)}
          saving={updating}
          emptyPlaceholder='No notes yet'
        />
      </Card>

      <Associations
        showOnly={showOnly}
        forEntity={{
          type: 'Topic',
          _id: topic._id,
          name: topic.name,
        }}
        setAlert={setAlert}
        refreshCount={refreshAssociationCount}
      />

      <hr />
      {editingNotes && canManagePublishedTopics() &&
        <PublishTopicButton
          topic={topic}
          topicApi={topicApi}
          onPublishSuccess={(action, updatedTopic) => {
            setAlert({
              variant: 'success',
              message: `This topic has been ${action}.`
            });
          }}
          onPublishFail={() => {
            setAlert({
              variant: 'danger',
              message: 'Oh no, something went wrong! Try again?'
            });
          }}
        />
      }
      {editingNotes &&
        <DeleteTopicButton
          topic={topic}
          deleteTopic={deleteTopic}
          deleting={deleting}
          onDeleteSuccess={() => history.push(backUrl)}
          onDeleteFail={() => {
            setAlert({
              variant: 'danger',
              message: 'Oh no, something went wrong! Try again?'
            });
          }}
        />
      }
    </div>
  );
};

export default Topic;