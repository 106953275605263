import TagForm from './TagForm';

const Aliases = ({
  aliases,
  editable,
  onSave,
  label = 'Aliases'
}) => {
  if (!editable) {
    if (!aliases || !aliases.length) return null;
    return <p className='m-0'>{label}: {aliases.join(', ')}</p>;
  }

  return (
    <div className='bg-light p-3 m-1 rounded border shadow-sm'>
      <p className='h5'>{label}</p>
      <TagForm
        initialTags={aliases}
        onChange={tags => onSave(tags)}
        badgeClasses='m-0 px-1'
      />
    </div>
  );
};

export default Aliases;