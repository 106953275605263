import Character, { CharacterIntro } from './character/Character';
import Region, { RegionIntro } from './region/Region';
import Element, { ElementIntro } from './element/Element';
import Book, { BookIntro } from './book/Book';
import ArtifactSet, { ArtifactSetIntro } from './artifact/ArtifactSet';
import Weapon, { WeaponIntro } from './weapon/Weapon';
import Monster, { MonsterIntro } from './monster/Monster';
import Item, { ItemIntro } from './item/Item';
import LoadingScreen, {
  LoadingScreenIntro,
} from './loadingScreen/LoadingScreen';

import CharacterListItem from './character/CharacterListItem';
import ArtifactSetListItem from './artifact/ArtifactSetListItem';
import BookListItem from './book/BookListItem';
import MonsterListItem from './monster/MonsterListItem';
import ItemListItem from './item/ItemListItem';
import WeaponListItem from './weapon/WeaponListItem';
import RegionListItem from './region/RegionListItem';
import ElementListItem from './element/ElementListItem';
import LoadingScreenListItem from './loadingScreen/LoadingScreenListItem';

import * as ap from './associationPresenters';

export const typeToComponent = {
  Character: {
    Intro: CharacterIntro,
    Body: Character,
  },
  Book: {
    Intro: BookIntro,
    Body: Book,
  },
  ArtifactSet: {
    Intro: ArtifactSetIntro,
    Body: ArtifactSet,
  },
  Weapon: {
    Intro: WeaponIntro,
    Body: Weapon,
  },
  Monster: {
    Intro: MonsterIntro,
    Body: Monster,
  },
  Item: {
    Intro: ItemIntro,
    Body: Item,
  },
  LoadingScreen: {
    Intro: LoadingScreenIntro,
    Body: LoadingScreen,
  },
  Region: {
    Intro: RegionIntro,
    Body: Region,
  },
  Element: {
    Intro: ElementIntro,
    Body: Element,
  },
};

export const typeToListComponent = {
  Character: CharacterListItem,
  Book: BookListItem,
  ArtifactSet: ArtifactSetListItem,
  Weapon: WeaponListItem,
  Monster: MonsterListItem,
  Item: ItemListItem,
  LoadingScreen: LoadingScreenListItem,
  Region: RegionListItem,
  Element: ElementListItem,
};

export const associationPresenter = (association) => {
  return ap[association.from.type](association) ||
    new Error(`association presenter does not exist for type`, association);
};

export const getEntityRoute = (type) => {
  return typeToPath[type] ||
    new Error(`path does not exist for entity type ${type}`);
};

export const typeToPath = {
  Character: 'characters',
  Story: 'stories',
  Talent: 'talents',
  Voiceline: 'voicelines',
  Book: 'books',
  BookVolume: 'bookVolumes',
  ArtifactSet: 'artifactSets',
  Artifact: 'artifacts',
  Weapon: 'weapons',
  Monster: 'monsters',
  Item: 'items',
  LoadingScreen: 'loadingScreens',
  Region: 'regions',
  Element: 'elements',
  Topic: 'topics',
}

export const createableTypes = ['Character', 'Region'];

export const toTargets = [
  'Character',
  'Region',
  'Element',
  'Topic',
]

export const fromTargets = [
  'Topic',
  'Voiceline',
  'Story',
  'BookVolume',
  'Artifact',
  'Weapon',
  'Item',
  'Monster',
  'LoadingScreen',
];
