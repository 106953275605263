
import { compareOrder } from '../utils';

import {
  EntityCard,
  ListItemTitle,
  EntityCardTitle,
  LoreMissing,
} from '../../wikiBuilder';

export const Story = ({ story, belongsTo }) => {
  return (
    <EntityCard
      associable
      from={{
        data: story,
        type: 'Story',
        belongsTo
      }}
      Title={() => <ListItemTitle title={story.name} />}
      htmlContent={story.html}
      theme={belongsTo?.element?.name?.toLowerCase()}
    >
      {!story.html && <LoreMissing />}
    </EntityCard>
  );
};

const Stories = ({ stories, belongsTo }) => {
  return (
    <div className={'w-100'}>
      <EntityCard
        Title={() => <EntityCardTitle title={'Stories'} />}
        bodyClasses='px-3'
        theme={belongsTo?.element?.name?.toLowerCase()}
      >
        {stories?.length === 0 ? <p className='text-muted ml-3'>No stories yet</p> :
          stories?.sort(compareOrder)?.map((story, i) => <Story story={story} belongsTo={belongsTo} key={`story_${i}`} />)
        }
      </EntityCard>
    </div>
  );
};

export default Stories;