import Card from 'react-bootstrap/Card';
import { InfoCircleFill } from 'react-bootstrap-icons';
import RichTextBox from '../slateEditor/RichTextBox';

const isEmpty = (notes) => {
  return (
    notes.length === 1 &&
    notes[0].children.length === 1 &&
    notes[0].children[0]?.text === ''
  );
};

const AssociationNotes = ({ notes, editable, onSave, onCancel, saving }) => {
  const jsonNotes = JSON.parse(notes);

  if (!editable && (!notes || isEmpty(jsonNotes))) return null;

  return (
    <Card className='w-100 my-3 shadow-sm'>
      <div className='h5 mb-0 px-3 py-2 bg-hydro'>
        <InfoCircleFill className='mr-2' />
        Annotations
      </div>
      <hr className='m-0 bg-info' />
      <div className='mx-3'>
        <RichTextBox
          value={jsonNotes}
          editable={editable}
          onSave={onSave}
          onCancel={onCancel}
          saving={saving}
          emptyPlaceholder='No notes yet'
        />
      </div>
    </Card>
  );
}

export default AssociationNotes;