import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

import useGetPublishedTopics from '../hooks/useTopics/useGetPublishedTopics';
import useExternalLoreList from '../hooks/useExternalLore/useExternalLoreList';

import {
  HtmlHead,
  Topic,
  Sidebar,
} from '../wikiBuilder';

import ArticleBrowser from '../components/publishedViews/ArticleBrowser';

const ArticleRouter = () => {
  const match = useRouteMatch();
  const topicApi = useGetPublishedTopics();
  const externalApi = useExternalLoreList();

  const {
    topics,
    getTopics,
    fetching,
  } = topicApi;

  const {
    getExternals
  } = externalApi;

  useEffect(() => {
    if (getTopics) getTopics();
  }, [getTopics]);

  useEffect(() => {
    if (getExternals) getExternals();
  }, [getExternals]);

  if (fetching || !topics) return (
    <div className='d-flex justify-content-center align-items-center'>
      <Spinner animation='border' />
    </div>
  );

  return (
    <Switch>

      <Route path={`${match.path}/:topicTitle/`}>
        <Topic
          showOnly
          topicApi={topicApi}
          backUrl={match.path}
        />
      </Route>

      <Route path={match.path}>
        <ArticleBrowser topics={topics} externalApi={externalApi} />
      </Route>

    </Switch>
  );
};

const PublishedArticles = () => {
  
  return (
    <>
      <HtmlHead title='Articles' />

      <Col lg={9}>
        <ArticleRouter />
      </Col>

      <Col lg={3} sm={6}>
        <Sidebar />
      </Col>
    </>
  );
}

export default PublishedArticles;
