import { useState } from 'react';
import useUser from '../../contexts/useUser';
import { EntityTableRowTitle } from '../entity';

import AssociationNotes from './AssociationNotes';
import EditAssociationButton from './EditAssociationButton';

export const AssociationTableRow = ({
  showOnly,
  title,
  imageUrl,
  className,
  children,
  htmlContent,
  notes,
  association,
  assocApi,
  setAlert,
  titleColSpan = 3,
}) => {
  const [editing, setEditing] = useState(false);

  const canEditAssociations = useUser(state => state.canEditAssociations);

  const {
    updateAssociation,
    updating,
  } = assocApi;

  const handleSave = async (payload) => {
    const succeeded = await updateAssociation(association._id, payload);
    if (succeeded) {
      setAlert(null);
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  };

  return (
    <tr className='row m-0 p-0'>
      <td className={`col-${titleColSpan} font-weight-bold`}>
        <EntityTableRowTitle title={title} imageUrl={imageUrl} />
      </td>
      <td className={`col-${11-titleColSpan}`}>
        {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent}} />}
        {children}
        <div className='mt-3'>
          <AssociationNotes
            notes={notes}
            editable={editing}
            onSave={(value) => handleSave({
              markdownNotes: JSON.stringify(value),
            })}
            onCancel={() => setEditing(false)}
            saving={updating}
          />
        </div>
      </td>
      <td className='col-1'>
        {(!showOnly && canEditAssociations()) && (
          <EditAssociationButton
            association={association}
            setEditable={setEditing}
            editable={editing}
            assocApi={assocApi}
            onDeleteFail={() => setAlert({
              variant: 'danger',
              message: 'Something went wrong while deleting. Try again?'
            })}
          />
        )}
      </td>
    </tr>
  );
}

export default AssociationTableRow;
