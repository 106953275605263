import { useEffect } from 'react';

const VerticalAd = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className='adsbygoogle'
      style={{ display: 'block' }}
      data-ad-client='ca-pub-6027964035318664'
      data-ad-slot='7656140303'
      data-ad-format='auto'
      data-full-width-responsive='true'
    />
  );
}

export default VerticalAd;
