import {
  EntityCard,
  ListItemTitle,
  LoreMissing,
} from '../../wikiBuilder';

export const compareArtifacts = (a, b) => {
  const correctOrder = [
    'Flower of Life',
    'Plume of Death',
    'Sands of Eon',
    'Goblet of Eonothem',
    'Circlet of Logos',
  ];

  if (correctOrder.indexOf(a.slot) < correctOrder.indexOf(b.slot)) {
    return -1;
  }
  if (correctOrder.indexOf(a.slot) > correctOrder.indexOf(b.slot)) {
    return 1;
  }
  return 0;
}

const Artifact = ({ artifact, belongsTo, startClosed = false }) => {
  const {
    name,
    imageUrl,
    description,
    lore,
  } = artifact;
  
  return (
    <EntityCard
      associable
      from={{
        data: artifact,
        type: 'Artifact',
        belongsTo
      }}
      Title={() => 
        <ListItemTitle
          title={name}
          imageUrl={imageUrl}
        />
      }
      startClosed={startClosed}
      htmlIntro={description}
      htmlContent={lore}
    >
      {!lore && <LoreMissing />}
    </EntityCard>
  );
};

export default Artifact;
