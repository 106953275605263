import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';

const NewTopicForm = ({ onCreateTopic, label, className, creating }) => {
  const [newTopicName, setNewTopicName] = useState('');
  const [containsQuestionMarks, setContainsQMarks] = useState(false);

  useEffect(() => {
    setContainsQMarks(newTopicName.includes('?'));
  }, [newTopicName]);

  return (
    <Form
      inline
      className={`w-100 d-flex ${className}`}
      onSubmit={(e) => {
        e.preventDefault();
        if (newTopicName && !containsQuestionMarks) {
          onCreateTopic(newTopicName)
        }
      }}
    >
      <Form.Label htmlFor="new-topic-name" srOnly>
        {label || 'Topic Name'}
      </Form.Label>
      <InputGroup className="mr-sm-2 w-50">
        <InputGroup.Prepend>
          <InputGroup.Text>{label || 'Topic Name'}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          id="new-topic-name"
          placeholder='Mondstadt Aristocracy'
          value={newTopicName}
          onChange={e => setNewTopicName(e.target.value)}
        />
        
      </InputGroup>
      <Button
        variant='success'
        className="font-weight-bold ml-2"
        disabled={!newTopicName || containsQuestionMarks}
        onClick={() => onCreateTopic(newTopicName)}
      >
      {creating
        ? <Spinner animation='border' size='sm' />
        : <span>Create Topic</span>
      }
      </Button>
      {containsQuestionMarks && (
        <Form.Text className='text-danger mx-2 my-auto'>? not allowed</Form.Text>
      )}
    </Form>
  );
}

export default NewTopicForm;