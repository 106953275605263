import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';

const Constellation = ({ constellations, latinName, englishName }) => {
  return (
      <Card className='mb-3'>
        <Card.Header className='h4'>
          Constellation{latinName && `: ${latinName}`}
        </Card.Header>
        {englishName && <div className="mb-2 text-muted">{englishName}</div>}
        <div>
          {(!constellations || constellations?.length === 0) ? <p className='text-muted'>No constellations yet</p> : (
            <ListGroup className="list-group-flush border-0">
              {constellations.map((con, i) => <ListGroup.Item key={`constel_${i}`}>{con.name}</ListGroup.Item>)}
            </ListGroup>
          )}
        </div>
      </Card>
  );
};

export default Constellation;