
import {
  EntityTable,
  EntityCard,
  EntityCardTitle,
  EntityTableRow,
} from '../../wikiBuilder';

import { compareOrder } from '../utils';

export const Talent = ({ talent, belongsTo }) => (
  <EntityTableRow
    from={{
      data: talent,
      type: 'Talent',
      belongsTo
    }}
    title={talent.name}
    titleColSpan={4}
  >
    {talent.lore
      ? (<p>{talent.lore}</p>)
      : (<span className='text-secondary'>No lore for this talent</span>)
    }
  </EntityTableRow>
);

const Talents = ({ talents, belongsTo }) => {
  return (
    <div className='w-100'>
      <EntityCard
        Title={() => <EntityCardTitle title={'Talents'} />}
        bodyClasses='px-3'
        theme={belongsTo?.element?.name?.toLowerCase()}
      >
        {talents?.length === 0 ? <p className='text-muted'>No talents yet</p> : (
          <EntityTable
            titleColName='Talent'
            titleColSpan={4}
          >
            {talents.sort(compareOrder)?.map((talent, i) => 
              <Talent talent={talent} belongsTo={belongsTo} key={`talent_${i}`} />
            )}
          </EntityTable>
        )}
      </EntityCard>
    </div>
  );
};

export default Talents;
