
import { serializeToHtml } from '@react-force/slate-editor';
import sanitizeHtml from 'sanitize-html';

import TextEditor from './TextEditor';
import HelpPopover from '../HelpPopover';

const Message = () => (
  <div>
    <p>For now, please avoid pasting images into the editor. We're still figuring out a good way to handle them.</p>
    <p>If saving your notes fails and you have an image, try removing the image and including a link to it instead.</p>
    <p><strong>Shortcuts</strong></p>
    <ul>
      <li>⌘B - Bold</li>
      <li>⌘U - Underline</li>
      <li>⌘I - Italics</li>
      <li>⌘S - Save</li>
      <li>⌘⌥S - Save and Close</li>
    </ul>
    <p>All other formatting options in popup menu after selecting text.</p>
  </div>
);

const RichTextBox = ({
  value,
  onCancel,
  onSave,
  onChange,
  saving,
  editable,
  emptyPlaceholder = null,
  raised
}) => {

  if (editable) return (
    <div className={`slate-editor m-3 ${raised && 'shadow-sm border rounded'}`}>
      <div className='d-flex justify-content-end m-2'>
        <HelpPopover
          Message={Message}
        />
      </div>
      <TextEditor
        initialValue={value}
        onCancel={onCancel}
        onChange={onChange}
        onSave={onSave}
        saving={saving}
      />
    </div>
  );

  if (value) return (
    <div
      className={`slate-editor`}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(serializeToHtml(value), {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
          allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            img: [ 'src' ]
          },
          allowedSchemes: [ 'data', 'http', 'https' ]
        })
      }}
    />
  );

  if (emptyPlaceholder) return <p className={`text-muted my-3`}>{emptyPlaceholder}</p>;

  return null;
};

export default RichTextBox;
