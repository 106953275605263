import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import Constellation from './Constellation';
import Talents from './Talents';
import Stories from './Stories';
import Voicelines from './Voicelines';

import {
  Star,
  ImageCard,
  Aliases,
} from '../../wikiBuilder';

const Namecard = ({ namecard, className }) => (
  <Card className={`p-0 ${className}`}>
    <ImageCard className={'mb-3'} url={namecard?.imageUrl} />

    {namecard?.title && <Card.Header className='text-center font-weight-bold bg-white'>{namecard?.title}</Card.Header>}
    <Card.Body>
      <Card.Text>
        {!namecard?.description || namecard?.description?.toLowerCase().includes('to be added')
          ? <span className='text-muted'>No namecard description yet</span>
          : namecard?.description
        }
      </Card.Text>
    </Card.Body>
  </Card>
);

const CharacterSlate = ({ character, className }) => {
  const {
    birthday,
    region,
    allegiance,
    element,
    weaponType,
  } = character;

  return (
      <Card.Body>
        {birthday && <div className=''><strong>Birthday: </strong>{birthday}</div>}
        {region && (
          <div className=''>
            <strong>Region: </strong>{region.name}
          </div>
        )}
        {allegiance && (
          <div className=''>
            <strong>Allegiance: </strong>{allegiance}
          </div>
        )}
        {element && (
          <div className=''>
            <strong>Element: </strong>{element.name}
          </div>
        )}
        {weaponType && (
          <div className=''>
            <strong>Weapon: </strong>{weaponType.name}
          </div>
        )}
      </Card.Body>
  );
};

export const CharacterIntro = ({ 
  resource: character,
  editable,
  onSave,
}) => {
  const {
    name,
    rarity,
    title,
    attributesDescription,
    element,
    gachaImageUrl,
    aliases
  } = character;

  return (
    <Row className='mb-3'>
      <Col md='6'>
        <Card className={`p-0 mb-3`}>
          <Card.Header className={`bg-${element?.name?.toLowerCase()}`}>
            <span className={'h2 mr-2 font-weight-bold'}>{name}</span>
            <div className={'d-inline-block'}>
              {!rarity ? <p className='h5 text-muted'>Rarity unknown</p> : (
                [...Array(rarity)].map((_, i) => <Star key={i} />)
              )}
            </div>
            {title ? <div className={'h4'}>"{title}"</div> : <div className={'h5 text-muted'}>No title yet</div>}
            <Aliases
              aliases={aliases}
              editable={editable}
              onSave={onSave}
            />
          </Card.Header>
          <ListGroup className="list-group-flush">
            {attributesDescription && 
              <ListGroupItem>
                <p className={'attributes_description'}>{attributesDescription}</p>
              </ListGroupItem>
            }
          </ListGroup>
          <CharacterSlate
            character={character}
          />
        </Card>
      </Col>
      <Col md='6'>
        <ImageCard className={'mb-3'} url={gachaImageUrl} />
      </Col>
    </Row>
  );
}

const Character = ({ resource: character }) => {
  const {
    talents,
    stories,
    voicelines,
    constellation,
    constellations,
    namecard,
  } = character;

  return (
    <div className='w-100'>
      <hr />

      <Row className='mb-3'>
        <Col md='6'>
          <Constellation
            latinName={constellation}
            constellations={constellations}
          />
        </Col>

        <Col md='6'>
          <Namecard className={'mb-3'} namecard={namecard} />
        </Col>
      </Row>
      <Talents talents={talents} belongsTo={character} />
      <Stories stories={stories} belongsTo={character} />
      <Voicelines voicelines={voicelines} belongsTo={character} />
    </div>
  );
}

export default Character;
