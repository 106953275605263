import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';
import { urlEncode, encodeBase64 } from '@techteia/utility';
import { getEntityRoute } from '../components/entityConfig';

import { handleError } from './utils';

const useEntityList = ({ type, immediate }) => {
  const { get, response, request, loading, error } = useFetch();
  const [entityList, setEntityList] = useState([]);

  const params = {
    filter: {
      dateInactive: null,
    },
    unset: [ 'markdownNotes' ]
  }
  
  const getEntityList = useCallback(async () => {
    const url = type
      ? `${getEntityRoute(type)}?${urlEncode({ data: encodeBase64(JSON.stringify(params)) })}`
      : 'entities';
    try {
      const entities = await get(url);
      if (response.ok) {
        setEntityList(entities);
      }
    } catch (e) {
      handleError('Error getting entityList:', e, { type });
    }
  }, [response, get, type]);

  useEffect(() => {
    if (immediate) getEntityList();
  }, [immediate, getEntityList]);

  return {
    getEntityList,
    entityList,
    request,
    response,
    loading,
    error,
  };
};

export default useEntityList;
