import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from "react-router-dom";

import {
  toUri,
  RichTextBox,
} from '../../wikiBuilder';

const TopicPreviewCard = ({ topic, baseUrl, showOnly }) => {
  const [hover, setHover] = useState(false);
  return (
    <Card
      className={`my-3 topic-preview-card ${hover ? 'shadow-lg' : 'shadow-sm'}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={`${baseUrl}/${toUri(topic.articleTitle)}`} className='text-decoration-none'>
        <Card.Body className='pb-2 text-dark'>
          <Card.Title className='font-weight-bold'>
            <div className='float-right d-flex'>
              {showOnly && <Badge pill className='bg-geo text-geo py-2 px-3 mx-1'>Genshin Lore</Badge>}
              {!showOnly && topic.datePublished &&
                <Badge pill variant='success' className='py-2 px-3 mx-1'>Published</Badge>
              }
            </div>
            <p className='m-0'>{topic.articleTitle}</p>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{topic.aliases.join(', ')}</Card.Subtitle>
          <div>
            <div style={{
              maxHeight: '100px',
              overflow: 'hidden'
            }}>
            <RichTextBox
              value={topic.markdownNotes ? JSON.parse(topic.markdownNotes) : null}
              emptyPlaceholder="There aren't any notes yet"
            />
            </div>
          </div>
        </Card.Body>
      </Link>

      <Card.Footer className='text-muted'>
        Updated on: {new Date(topic.dateUpdated).toLocaleDateString()}
      </Card.Footer>
    </Card>
  );
};

export default TopicPreviewCard;