import {
  useRouteMatch,
} from "react-router-dom";

import TopicPreviewCard from './TopicPreviewCard';

const TopicList = ({ topics, showOnly, showPlaceholder }) => {
  const { url } = useRouteMatch();

  if (topics.length) return topics.map((t, i) => 
    <TopicPreviewCard
      showOnly={showOnly}
      topic={t}
      baseUrl={url}
      key={`topic_card_${i}`}
    />
  );

  if (showPlaceholder) return (
    <p className='text-muted m-3'>There aren't any native matches</p>
  );

  return null;
};

export default TopicList;