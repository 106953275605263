import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import Overlay from 'react-bootstrap/Overlay';


const CreateAssociationActions = ({
  buttonRef,
  to,
  ack,
  loading,
  onCreate,
  onCancel,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <ButtonToolbar className='float-right mt-3' aria-label="Association From Submit">
      <Button
        className='mb-2 mx-2'
        variant="light"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <div 
        onMouseEnter={() => {
          if (!to || !ack) setShowTooltip(true);
        }}
        onPointerLeave={() => {
          setShowTooltip(false);
        }}
        className='mb-2'
      >
        <Button
          ref={buttonRef}
          variant="primary"
          disabled={!to || !ack || loading}
          onClick={onCreate}
        >
          {loading ? <Spinner animation='border' size='sm' /> : 'Save'}
        </Button>
      </div>
      <Overlay target={buttonRef.current} show={showTooltip} placement="top">
        {(props) => (
          <Tooltip {...props}>
            {!to && <span>Gotta link to something</span>}
            {to && !ack && <span>Pls tell me this association is meaningful.</span>}
          </Tooltip>
        )}
      </Overlay>
    </ButtonToolbar>
  );
}

export default CreateAssociationActions;