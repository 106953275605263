import Button from 'react-bootstrap/Button';
import { PencilFill } from 'react-bootstrap-icons';

import {
  InlineForm,
} from '../../wikiBuilder';

const TopicTitle = ({
  showOnly,
  title,
  onSave,
  editable,
  setEditable,
  saving,
}) => {
  if (!editable) return (
    <h2 className='d-flex justify-content-between'>
      <span className={'mr-2 font-weight-bold'}>{title}</span>
      {!showOnly &&
        <Button
          variant='light'
          onClick={() => setEditable(true)}
        >
          <PencilFill size='15px' />
        </Button>
      }
    </h2>
  );

  return (
    <InlineForm
      initialValue={title}
      onClose={() => setEditable(false)}
      onSave={onSave}
      saving={saving}
    />
  );
}

export default TopicTitle;
