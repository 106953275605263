import React, { useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

import { CursorFill } from 'react-bootstrap-icons';

import Anemo from '../../../images/anemo200.png';

import AddAssociationsMenu from './AddAssociationsMenu';

const SuccessToast = ({ show, onClose }) => (
  <Toast
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
    }}
    className='p-0 m-0'
    onClose={onClose}
    show={show}
    delay={2000}
    autohide
    onClick={e => e.stopPropagation()}
  >
    <Toast.Header>
      <img src={Anemo}
        style={{
          height: 24,
          width: 24,
        }} className="mr-2" alt="Anemo icon" />
      <strong className="mr-auto">Success</strong>
    </Toast.Header>
    <Toast.Body>
      Thanks for your contribution!
    </Toast.Body>
  </Toast>
);

export const AddAssociationsButton = ({
  from,
  className,
  assocApi,
  setMouseOver,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const popover = useRef(null);

  if (!from.data) return null;

  return (
    <>
      <SuccessToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
      />
      <Button
        ref={popover}
        variant='dark'
        title={`create association for ${from.data.name}`}
        className={`${className} float-right px-2 pb-1 pt-0`}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setMouseOver?.(true)
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setMouseOver?.(false)
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowForm(!showForm);
        }}
      >
        <CursorFill />
      </Button>
      <AddAssociationsMenu
        from={from}
        overlayTarget={popover.current}
        isOpen={showForm}
        onFail={() => setShowForm(false)}
        onSuccess={() => {
          setShowSuccessToast(true);
          setShowForm(false);
        }}
        onClose={() => setShowForm(false)}
        assocApi={assocApi}
      />
    </>
  );
};

export default AddAssociationsButton;