import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import '../../App.css';
import { CaretDownFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import {
  EntityAvatar,
  AssociatedToBlock,
} from '../common';

import AddAssociationsButton from '../association/creation/AddAssociationsButton';
import useAssociationList from '../../hooks/useAssociations/useAssociationList';
import useUser from '../../contexts/useUser';

export const EntityCardTitle = ({ title, imageUrl }) => (
  <div className='d-flex align-items-center'>
    {imageUrl && <EntityAvatar size='50px' src={imageUrl} className='mr-3'/>}
    <div className='h4 font-weight-bold my-0 py-0'>{title}</div>
  </div>
);

const EntityCard = ({
  associable,
  from,
  Title,
  htmlIntro,
  bodyClasses,
  startClosed,
  bordered,
  htmlContent,
  linkTo,
  theme,
  children
}) => {
  const [buttonMouseOver, setButtonMouseOver] = useState(false);
  const [headerMouseOver, setHeaderMouseOver] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(!startClosed);
  const loggedIn = useUser(state => state.loggedIn);
  const userTopics = useUser(state => state.topics);

  const assocApi = useAssociationList(from?.type, 'from');
  const {
    associations,
    getAssociations,
  } = assocApi;

  const element = theme || 'light';
  const bg = `bg-${element}`;
  const activeBg = `bg-${element}-hover`;

  const handleToggle = useAccordionToggle('0', () => {
    if (!hasBeenOpened) setHasBeenOpened(true);
  });

  useEffect(() => {
    if (from?.data?._id && userTopics && hasBeenOpened) {
      getAssociations(from.data._id, userTopics);
    }
  }, [from?.data?._id, userTopics, hasBeenOpened]);

  return (
    <Accordion
      defaultActiveKey={startClosed ? undefined : '0'}
      className='my-2 w-100'
    >
      <Card
        className={`
          ${!bordered ? 'border-0' : ''} 
          association-hook-card 
          ${buttonMouseOver ? 'shadow-lg' : null}
        `}
        bg={'light'}
        text={'dark'}
      >
        <Accordion.Toggle
          as={Card.Header}
          className={`
            ${headerMouseOver ? activeBg : bg} 
            association-hook-card py-2
            d-flex align-items-center justify-content-between
          `}
          style={{ cursor: 'pointer' }}
          eventKey='0'
          onMouseEnter={() => setHeaderMouseOver(true)}
          onMouseLeave={() => setHeaderMouseOver(false)}
          onClick={handleToggle}
        >
          {linkTo
            ? (
              <Link to={linkTo} role='button' className='text-dark' onClick={e => e.stopPropagation()}>
                <Title />
              </Link>
            ) : <Title />
          }
          {(associable && from && loggedIn)
            ? (
              <AddAssociationsButton
                from={from}
                setMouseOver={setButtonMouseOver}
                assocApi={assocApi}
              />
            ) : <div className='float-right'><CaretDownFill /></div>
          }
        </Accordion.Toggle>

        <Accordion.Collapse eventKey='0'>
          <Card.Body className={`${bodyClasses}`}>
            {htmlIntro && (
              <i>
                <div className='mb-3' dangerouslySetInnerHTML={{ __html: htmlIntro}} />
              </i>
            )}
            {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent}} />}
            {children}
            {from && (
              <AssociatedToBlock
                associations={associations}
                entity={from.data._id}
              />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default EntityCard;
