import { useState } from 'react';
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead';

export const TopicSearchBar = ({
  onSelect,
  topics,
  placeholder = 'Select a topic',
  ...rest
}) => {
  const [menuOpen, setMenuOpen] = useState(undefined);

  return (
    <Typeahead
      id="topic_searchbar"
      className={'w-100 my-1'}
      onChange={onSelect}
      onBlur={() => setMenuOpen(false)}
      onFocus={() => setMenuOpen(undefined)}
      isLoading={!topics}
      open={menuOpen}
      labelKey='articleTitle'
      options={topics || []}
      paginationText={'More...'}
      placeholder={placeholder}
      {...rest}
    >
      {({ onClear, selected }) => (
        <div className="rbt-aux">
          {!!selected.length && <ClearButton onClick={onClear} />}
        </div>
      )}
    </Typeahead>
  );
};

export default TopicSearchBar;


/* CUTE TYPEAHEAD SEARCH
 * consider using for entity search?
 * just want to keep available
 *
 * <TopicSearchBar
        topics={topics}
        onSelect={handleTopicSelect}
        size='lg'
        filterBy={['aliases']}
        minLength={3}
        renderMenuItemChildren={(option, props, index) => (
          <>
            <p className='m-0'>
              <Highlighter search={props.text} highlightClassName='font-weight-bold'>
                {option.articleTitle}
              </Highlighter>
            </p>
            <p className='text-muted m-0'>
              <Highlighter search={props.text} highlightClassName='font-weight-bold'>
                {option.aliases.join(', ')}
              </Highlighter>
            </p>
          </>
        )}
      />
  *
  */