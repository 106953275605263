import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { typeToListComponent } from './entityConfig';
import useEntityList from '../hooks/useEntityList';
import { compareName } from './utils';

import { toUri, toTitleCase, HtmlHead } from '../wikiBuilder';

const EntityList = ({ type }) => {
  const {
    entityList,
    getEntityList,
    loading
  } = useEntityList({ type });

  useEffect(() => {
    getEntityList();
  }, [type, getEntityList]);

  if (loading || entityList[0]?.type !== type) return <Spinner animation='border' />;

  const Component = typeToListComponent[type];
  return (
    <>
      <HtmlHead title={`${toTitleCase(type)}s`} />

      {entityList.sort(compareName).map((en, i) => (
        <Component
          entity={en}
          linkTo={`/researcher/${type}/${toUri(en.name)}`}
          key={`listitem_${i}`}
        />
      ))}
    </>
  );
};

export default EntityList;
