import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import FormControl from 'react-bootstrap/FormControl';

const DeleteExternalConfirmationModal = ({
  external,
  isOpen,
  onClose,
  onDeleteSuccess,
  onDeleteFail,
  externalApi,
}) => {
  const [confirmationInput, setConfirmationInput] = useState('');

  const {
    deleteExternal,
    deleting,
  } = externalApi;

  const handleDelete = async () => {
    const succeeded = await deleteExternal(external._id);
    if (succeeded) {
      setConfirmationInput('');
      onClose();
    } else {
      onDeleteFail?.();
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirm
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Type the name of this entry to confirm deletion.</p>
        <FormControl
          value={confirmationInput}
          placeholder={external.name}
          onChange={e => setConfirmationInput(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDelete}
          disabled={external.name !== confirmationInput}
        >
          {deleting
            ? <Spinner animation='border' size='sm' />
            : <span>Delete</span>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteExternalConfirmationModal;
