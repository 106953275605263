import { compareOrder } from '../utils';

import {
  EntityTable,
  EntityCard,
  EntityCardTitle,
  EntityTableRow,
  LoreMissing,
} from '../../wikiBuilder';

export const Voiceline = ({ voiceline, belongsTo }) => (
  <EntityTableRow
    associable
    from={{
      data: voiceline,
      type: 'Voiceline',
      belongsTo
    }}
    title={voiceline.name}
    htmlContent={voiceline.content}
  >
    {!voiceline.content && <LoreMissing />}
  </EntityTableRow>
);

const Voicelines = ({ voicelines, belongsTo }) => {
  console.log(voicelines)
  return (
    <div className='w-100'>
      <EntityCard
        Title={() => <EntityCardTitle title={'Voicelines'} />}
        bodyClasses='px-3'
        theme={belongsTo?.element?.name?.toLowerCase()}
      >
        {voicelines?.length === 0 ? <p className='text-muted'>No voicelines yet</p> :
          <EntityTable titleColName='Line'>
            {voicelines?.sort(compareOrder)?.map((voiceline, i) => 
              <Voiceline voiceline={voiceline} belongsTo={belongsTo} key={`voiceline_${i}`} />
            )}
          </EntityTable>
        }
      </EntityCard>
    </div>
  );
};

export default Voicelines;
