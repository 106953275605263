import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import {
  TopicList,
} from '../../wikiBuilder';

import ExternalList from '../externalLore/ExternalList';

const AllArticles = ({ topics, externals, externalApi }) => {
  const [tab, setTab] = useState('articles');

  return (
    <Tabs
      activeKey={tab}
      onSelect={(t) => setTab(t)}
      className="mb-3"
    >
      <Tab eventKey="articles" title="Our Articles">
        <TopicList showOnly topics={topics} />
      </Tab>
      <Tab eventKey="kqm" title="External Library">
        <ExternalList externals={externals} externalApi={externalApi} />
      </Tab>
    </Tabs>
  );
}

export default AllArticles;