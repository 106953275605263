import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import useUser from '../../contexts/useUser';
import EditExternalForm from './EditExternalForm';
import DeleteExternalConfirmationModal from './DeleteExternalConfirmationModal';

const ExternalLoreToolbar = ({ onEdit, onDelete }) => {

  return (
    <ButtonGroup className='float-right'>
      <Button
        variant='link'
        className='w-100'
        onClick={onEdit}
      >
        Edit
      </Button>
      <Button
        variant='link'
        className='text-danger w-100'
        onClick={onDelete}
      >
        Delete
      </Button>
    </ButtonGroup>
  );
}

const ExternalLorePreview = ({ external, externalApi, editable }) => {
  const date = external.datePublished || Date.now();

  const canEditKqm = useUser(state => state.canEditKqm);

  const [editing, setEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleEdit = () => setEditing(true);
  const handleDelete = () => setShowDeleteConfirmation(true);

  if (editing) return <EditExternalForm
    external={external}
    updateExternal={externalApi.updateExternal}
    onSaveSuccess={() => setEditing(false)}
  />

  return (<>
    <Card className='shadow-sm mb-4'>
      <Card.Body>
        <Badge pill className='bg-electro text-electro float-right py-2 px-3'>External</Badge>
        <Card.Title className='font-weight-bold'><p>{external.name}</p></Card.Title>

        <Card.Text>
          # {external.tags?.join(', ')}
        </Card.Text>

        {external.sources.map(s => <Card.Link href={s.url} target='_blank'>{s.name}</Card.Link>)}
      </Card.Body>

      <Card.Footer className='text-muted'>
        Added on: {new Date(date).toLocaleDateString()}
        {canEditKqm() && editable && <ExternalLoreToolbar onEdit={handleEdit} onDelete={handleDelete} />}
      </Card.Footer>
    </Card>

    {externalApi && <DeleteExternalConfirmationModal
      external={external}
      isOpen={showDeleteConfirmation}
      onClose={() => setShowDeleteConfirmation(false)}
      externalApi={externalApi}
    />}
  </>);
}

export default ExternalLorePreview;
