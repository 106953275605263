import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { typeToComponent, toTargets } from './entityConfig';
import useEntity from '../hooks/useEntity/useEntity';
import useUser from '../contexts/useUser';

import {
  Alert,
  RichTextBox,
  Associations,
} from '../wikiBuilder';

const Entity = ({ entity }) => {
  const [alert, setAlert] = useState(null);
  const [editing, setEditing] = useState(false);
  const canEditPrimarySources = useUser(state => state.canEditPrimarySources);

  const {
    entity: resource,

    getEntity,
    fetching,

    updateEntity,
    updating,
  } = useEntity(entity.type);

  const handleSave = async (payload, shouldEndEditing) => {
    const succeeded = await updateEntity(resource._id, payload);
    if (succeeded) {
      setAlert(null);
    } else {
      setAlert({
        variant: 'danger',
        message: 'We had trouble saving that. Try again?'
      });
    }
  };

  const isToTarget = toTargets.includes(entity.type);

  useEffect(() => {
    if (!entity) return;
    
    getEntity(entity._id);
  }, [entity?._id, getEntity]);

  if (fetching || !resource) return <Spinner animation='border' />;

  const { Intro, Body } = typeToComponent[entity.type];
  
  return (
    <div className='w-100'>
      <Alert alert={alert} setAlert={setAlert} />
      {canEditPrimarySources() &&
        <Row>
          <Col className='mb-2 d-flex justify-content-end align-items-center float-right'>
            <Button
              variant='link'
              onClick={() => setEditing(!editing)}
              className={editing && 'text-danger'}
            >
              {editing ? 'Done' : 'Edit'}
            </Button>
            <Button
              variant='light'
              className={`px-0 ${!updating && 'invisible'}`}
            >
              <Spinner animation='border' size='sm' className='text-secondary' />
            </Button>
          </Col>
        </Row>
      }

      <Intro
        resource={resource}
        editable={editing}
        onSave={value => handleSave(value)}
      />

      <RichTextBox
        value={JSON.parse(resource.markdownNotes)}
        onSave={(value) => handleSave({
          markdownNotes: JSON.stringify(value),
        })}
        onCancel={() => setEditing(false)}
        saving={updating}
        editable={editing}
      />

      <Body
        resource={resource}
        setAlert={setAlert}
      />

      {isToTarget && (
        <>
          <h2 className='mt-5'>Other Mentions</h2>
          <hr />
          <Associations forEntity={entity} setAlert={setAlert} />
        </>
      )}
    </div>
  );
};

export default Entity;
