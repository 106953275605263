import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ChevronLeft } from 'react-bootstrap-icons';

import { toTargets } from '../../../components/entityConfig';
import useUser from '../../../contexts/useUser';
import useStore from '../../../hooks/useStore';
import TopicSearchBar from '../../topic/TopicSearchBar';
import { EntitySearchBar } from '../../../components/entitySearch/EntitySearch';

const TargetTypeSelector = ({
  from,
  onSelect,
  existingAssociations,
  topics,
}) => {
  const [targetType, setTargetType] = useState(null);

  const entityList = useStore(state => state.entityList);
  const loggedIn = useUser(state => state.loggedIn);
  const canAssociatePrimarySources = useUser(state => state.canAssociatePrimarySources);

  useEffect(() => {
    if (!canAssociatePrimarySources()) {
      setTargetType('Topic');
    }
  }, [loggedIn]);

  const isPermittedTopicTarget = t => {
    if (from.data.type === 'Topic' && from.data.articleTitle === t.articleTitle) {
      return false;
    }
    return !existingAssociations.find(a => t._id === a.to._id._id);
  }

  return (
    <Form.Group controlId="newAssocForm.SelectTo">
      <Form.Label className='font-weight-bold'>Link To</Form.Label>
      {!targetType &&
        <div className='d-flex justify-content-center align-items-center'>
          <Button
            variant='dark'
            className='mx-2'
            onClick={() => setTargetType('Primary Source')}
          >
            Primary Source
          </Button>
          <Button
            variant='dark'
            className='mx-2'
            onClick={() => setTargetType('Topic')}
          >
            Topic
          </Button>
        </div>
      }

      {targetType === 'Primary Source' && (
        <>
          <Button variant='light' onClick={() => setTargetType(null)} className='d-flex align-items-center'>
            <ChevronLeft className='mr-2'/>
            <span>Primary Source</span>
          </Button>
          <EntitySearchBar
            onSelect={onSelect}
            entityList={entityList.filter(en =>
              toTargets.includes(en.type)
              && en.type !== 'Topic'
              && !existingAssociations.find(a => en._id === a.to._id._id)
            )}
            placeholder={'Choose...'}
          />
        </>
      )}
      {targetType === 'Topic' && (
        <div>
          {canAssociatePrimarySources() &&
            <Button variant='light' onClick={() => setTargetType(null)} className='d-flex align-items-center'>
              <ChevronLeft />
              <span>Topic</span>
            </Button>
          } 
          <TopicSearchBar
            onSelect={t => !t.length ? onSelect(t) : onSelect([{ ...t[0], type: 'Topic' }])}
            topics={topics.filter(t => isPermittedTopicTarget(t))}
            placeholder={'Choose...'}
          />
        </div>
      )}
      
    </Form.Group>
  );
}

export default TargetTypeSelector;