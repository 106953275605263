import React from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import NewAssociationForm from './NewAssociationForm';

const AddAssociationsMenu = ({
  from,
  overlayTarget,
  isOpen,
  assocApi,
  onClose,
  onFail,
  onSuccess,
}) => {
  return (
    <Overlay target={overlayTarget} show={isOpen} placement='left'>
      {(props) => (
        <Popover
          {...props}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className='w-100'
        >
          <Popover.Content className='p-0'>
            <NewAssociationForm
              from={from}
              onCreateSuccess={onSuccess}
              onCreateFail={onFail}
              onClose={onClose}
              className='p-3'
              assocApi={assocApi}
            />
          </Popover.Content>
        </Popover>
      )}
    </Overlay>
  );
}

export default AddAssociationsMenu;
