import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { getEntityRoute, toTargets, fromTargets } from '../../components/entityConfig';
import { urlEncode, encodeBase64 } from '@techteia/utility';

import { handleError } from '../utils';

const params = {
  filter: {
    dateInactive: null,
  }
}

const useGetManyAssociations = (type, target) => {
  const {
    get,
    response,
    loading,
    error: fetchError,
  } = useFetch();

  const [error, setError] = useState(null);
  const [associations, setAssociations] = useState([]);

  useEffect(_ => setError(fetchError), [fetchError]);

  const getAssociations = useCallback(async (id) => {
    const url = `associations/${getEntityRoute(type)}/${id}?${urlEncode({ data: encodeBase64(JSON.stringify(params)) })}`;

    const isCorrectTarget = association => (
      association[target]._id._id === id
    );
    const followsAssociationRules = association => (
      fromTargets.includes(association.from.type) &&
      toTargets.includes(association.to.type) &&
      association.to._id && association.from._id // temporary - in case the association is to something deleted
    );

    try {
      const resource = await get(url);
      if (response.ok) {
        if (target)
          setAssociations(resource?.filter(r => isCorrectTarget(r) && followsAssociationRules(r)));
        else
          setAssociations(resource?.filter(r => followsAssociationRules(r)));
      }
    } catch (e) {
      handleError('Error getting associations:', e, { type, id });
    }
  }, [type, target]);

  return {
    getAssociations,
    associations,
    response,
    loading,
    error,
  };
};

export default useGetManyAssociations;
