import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import {
  TopicList,
} from '../../wikiBuilder';

import ExternalList from '../externalLore/ExternalList';

const FilteredArticles = ({ topics, externals, externalApi }) => {
  const [tab, setTab] = useState('all');

  return (
    <Tabs
      activeKey={tab}
      onSelect={(t) => setTab(t)}
      className="mb-3"
    >
      <Tab eventKey="all" title="All">
        <TopicList showOnly topics={topics} />
        <ExternalList externals={externals} externalApi={externalApi} suppressNote />
      </Tab>
      <Tab eventKey="articles" title="Our Articles">
        <TopicList showOnly topics={topics} showPlaceholder />
      </Tab>
      <Tab eventKey="kqm" title="External Library">
        <ExternalList externals={externals} externalApi={externalApi} showPlaceholder />
      </Tab>
    </Tabs>
  );
}

export default FilteredArticles;