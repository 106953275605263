import React from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import useUser from '../../contexts/useUser';

const EditAssociationsMenu = ({
  overlayTarget,
  isOpen,
  onEdit,
  onDelete,
}) => {
  const canAssociatePrimarySources = useUser(state => state.canAssociatePrimarySources);
  const canDissociatePrimarySources = useUser(state => state.canDissociatePrimarySources);
  const editingOwn = useUser(state => state.editingOwn);

  return (
    <Overlay target={overlayTarget} show={isOpen} placement="bottom">
      {(props) => (
        <Popover
          {...props}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Popover.Content className='p-0'>
            <ButtonGroup vertical>
              {(canAssociatePrimarySources() || editingOwn) && (
                <Button
                  variant='link'
                  className='w-100'
                  onClick={onEdit}
                >
                  Make Notes
                </Button>
              )}
              {(canDissociatePrimarySources() || editingOwn) && (
                <Button
                  variant='link'
                  className='text-danger w-100'
                  onClick={onDelete}
                >
                  Delete
                </Button>
              )}
            </ButtonGroup>
          </Popover.Content>
        </Popover>
      )}
    </Overlay>
  );
}

export default EditAssociationsMenu;
