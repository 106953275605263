import { useState, useCallback, useEffect } from 'react';
import { useFetch } from 'use-http';

import { handleError } from './utils';

const useGetUserMetadata = () => {
  const {
    get,
    response,
    error: fetchError,
  } = useFetch();

  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(fetchError);
  }, [fetchError]);

  const getMetadata = useCallback(async () => {
    try {
      const resource = await get('/users/@me');
      if (response.ok) {
        setMetadata(resource);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      handleError('Error getting user metadata:', e);
    }
  }, [response, get]);

  return {
    metadata,
    getMetadata,
    response,
    error,
  };
}

export default useGetUserMetadata;
