import Toast from 'react-bootstrap/Toast';
import { Youtube, Twitch, Twitter } from 'react-bootstrap-icons';
import { useGenshinAuth } from '../../contexts/GenshinAuthContext';
import useUser from '../../contexts/useUser';
import VerticalAd from './VerticalAd';

const Sidebar = () => {
  const { links } = useGenshinAuth();
  const loggedIn = useUser(state => state.loggedIn);

  return (
    <>
      <Toast>
        <Toast.Body className='d-flex flex-column justify-content-center align-items-center'>
          <p className='h5'>@wiggenwell</p>
          <div>
            <a href='https://www.youtube.com/channel/UCsWfXq_8Rx2zBun5PLwCuHQ' title='Youtube Link' target='_blank' rel='noreferrer'>
              <Youtube className='text-dark mr-3' size={24} />
            </a>
            <a href='https://www.twitch.tv/wiggenwell' title='Twitch Link' target='_blank' rel='noreferrer'>
              <Twitch className='text-dark mr-3' size={24} />
            </a>
            <a href='https://twitter.com/wiggenwell' title='Twitter Link' target='_blank' rel='noreferrer'>
              <Twitter className='text-dark' size={24} />
            </a>
          </div>
        </Toast.Body>
      </Toast>
      {!loggedIn && (
        <Toast>
          <Toast.Body>
            <a href={links.login}>Log in&nbsp;</a>
            to research your own topics
          </Toast.Body>
        </Toast>
      )}
      <Toast>
        <Toast.Body>
          Have feedback or suggestions? Stop by <code>#loresite</code> in the Discord!
        </Toast.Body>
      </Toast>
      <Toast>
        <Toast.Body>
          <h6 className='font-weight-bold mb-0'>Notice</h6>
          <p className='mb-0'>
            Since our data is sourced by&nbsp;
            <a
              href='https://genshin.honeyhunterworld.com/'
              title='Honey Impact link'
              target='_blank'
              rel='noreferrer'
            >
              Honey Impact
            </a>
            , some primary sources may not be currently live in game, or honey may not have updated it yet. If anything surprises you, that may be why!
          </p>
        </Toast.Body>
      </Toast>
      <iframe
        id='discord-widget'
        title='Discord widget'
        src="https://discord.com/widget?id=738133650809553037&theme=dark"
        allowtransparency="true"
        frameBorder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      >
      </iframe>
      <VerticalAd />
    </>
  );
}

export default Sidebar;