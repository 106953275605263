import {
  EntityCard,
  ListItemTitle,
  EntityBadge,
  toUri,
} from '../../wikiBuilder';

import { Link } from 'react-router-dom';

export const ItemListItem = ({ entity: item, ...rest }) => {
  if (!item.imageUrl) return null;
  return (
    <EntityCard
      associable
      from={{
        data: item,
        type: 'Item',
      }}
      Title={() => <ListItemTitle
        imageUrl={item.imageUrl}
        title={item.name}
      />}
      htmlIntro={item.lore ? item.description : undefined}
      htmlContent={item.lore ? item.lore : item.description}
      startClosed
      {...rest}
    >
      {!(item.description || item.lore) && <p className='text-muted ml-3'>No lore yet</p>}
      <div className='text-dark font-weight-bold'>
        {item?.uses.join(', ')}
      </div>
      {item.monsters.length !== 0 && (
        <div className='text-muted d-flex align-items-center flex-wrap'>
          Dropped by: {item.monsters.map((m, i) => 
            <Link
              to={`/researcher/Monster/${toUri(m.name)}`}
              role='button'
              key={`m_${i}`}
            >
              <EntityBadge
                imageUrl={m.imageUrl}
                name={m.name}
              />
            </Link>
          )}
        </div>
      )}
    </EntityCard>
  );
};

export default ItemListItem;