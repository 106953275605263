import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { StarFill} from 'react-bootstrap-icons';

export const Star = ({ className }) => (
  <StarFill size={24} className={`mr-1 mb-1 ${className}`} />
);

export const EntityAvatar = ({ size, src, className }) => (
  <div style={{
    height: size,
    width: size,
  }} className={`d-flex justify-content-center ${className}`}>
    <Image
      src={src}
      className='h-100'
      style={{
        objectFit: 'contain',
        maxWidth: size,
      }}
      roundedCircle
    />
  </div>
);

export const ListItemTitle = ({ title, imageUrl }) => (
  <div className='d-flex align-items-center'>
    {imageUrl && <EntityAvatar size='40px' src={imageUrl} className='mr-3' />}
    <div className='h5 font-weight-bold my-0'>{title}</div>
  </div>
);

export const ImageCard = ({ url, className }) => {
  if (!url) return null;
  return (
    <Card className={`p-0 w-100 ${className}`}>
      <Card.Img
        variant="top"
        style={{ 
          width: '100%', 
          maxHeight: '250px',
          objectFit: 'contain',
        }}
        src={url}
      />
    </Card>
  );
}

export const EntityBadge = ({ name, imageUrl }) => (
  <Badge variant="light" pill className='m-1 border'>
    <div className='d-flex align-items-center'>
      {imageUrl
        ? <EntityAvatar size='25px' src={imageUrl} className='mr-2' />
        : <div style={{ height: '25px' }} />
      }
      <div>{name}</div>
    </div>
  </Badge>
);

export const badgeLink = (a) => {
  if (a.to.type === 'Topic') {
    if (a.to._id.datePublished === null)
      return `/profile/${toUri(a.to._id.articleTitle)}`;
    else
      return `/articles/${toUri(a.to._id.articleTitle)}`;
  }
  return `/researcher/${a.to.type}/${toUri(a.to._id.name)}`;
}

export const AssociatedToBlock = ({ associations, entity }) => {
  if (!associations?.length) return null;

  return (
    <div className='text-muted mt-3 d-flex align-items-center flex-wrap'>
      <span>Associated to:</span>
      {associations.map((a, i) =>
        <Link
          to={badgeLink(a)}
          role='button'
          key={`${entity._id}_assoc_${i}`}
        >
          <EntityBadge
            imageUrl={a.to.type === 'Character' ? a.to._id.portraitImageUrl : a.to._id.imageUrl}
            name={a.to.type === 'Topic' ? a.to._id.articleTitle : a.to._id.name}
          />
        </Link>
      )}
    </div>
  );
}

export const LoreMissing = () => (
  <p className='text-muted'>There's no lore for this</p>
);

export const toUri = (str) => str.replace(/( )/g,'_');
export const fromUri = (str) => str.replace(/(_)/g,' ');
export const toTitleCase = (str) => str.replace(/([A-Z])/g, (match) => ` ${match}`).trim();